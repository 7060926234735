import React, { useEffect } from 'react';
import { Route, Redirect, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

export default function PrivateRoute (props) {
    const { path, isLogin, component} = props;

	const isLoggedIn = useSelector((state) => state.data.isLoggedIn)

	const history=useHistory();
useEffect(()=>{
	history.push("/login");
},[])
	
	return (
		<Route path={props.path} exact render={() => {
			return props.isLogin ? <div className="mainContent">{props.component}</div> : <Redirect to="/login" />
		}} ></Route>
	);
}

PrivateRoute.propTypes = {
	path: PropTypes.any,
	isLogin: PropTypes.bool.isRequired,
	component: PropTypes.any,

};