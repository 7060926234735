import ActionListModel from '../Models/ActionListModel';

function getActionListData() {
	var staticActionList = [];

	var actionItem1 = new ActionListModel();
	actionItem1.practice_id = 1;
	actionItem1.description =
		'This is an action that limtits the access of users.';
	staticActionList.push(actionItem1);

	var actionItem2 = new ActionListModel();
	actionItem2.practice_id = 1;
	actionItem2.description =
		'This is an additional step taken to limit the access of users.';
	staticActionList.push(actionItem2);

	var actionItem3 = new ActionListModel();
	actionItem3.practice_id = 2;
	actionItem3.description =
		'This action is for a different practice and should not display';
	staticActionList.push(actionItem3);

	return staticActionList;
}

function selectActions(practiceId) {
	const data = getActionListData();
	var selectedActions = data.filter(
		(actionItem) => actionItem.practice_id === practiceId
	);

	return selectedActions;
}

export default selectActions;
