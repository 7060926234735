import PropTypes from 'prop-types';
import React from 'react';
import {
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton,
	DialogActions,
	Button,
	makeStyles,
} from '@material-ui/core';
import { default as CloseIcon } from '@material-ui/icons/Close';
import PracticeDialogDiscussion from './_PracticeDialogDiscussion';
import PracticeDialogActions from './_PracticeDialogActions';
import PracticeDialogFileAttachments from './_PracticeDialogFileAttachments';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		textAlign: 'start',
		padding: theme.spacing(2),
		alignItems: 'center',
	},
}));

const PracticeDialog = (props) => {
	const { handleSubmit, handleClose,index, open, code, actionType } = props;
	const classes = useStyles();
// console.log(index);
	return (
		<Dialog
			className={classes.root}
			open={open}
			onClose={handleClose}
			fullWidth={true}
			maxWidth={'md'}
			
			>
			<DialogTitle >
				{code} - {actionType}
				<IconButton onClick={handleClose} style={{position:'absolute', top:'10px', right:'10px'}}>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent dividers={true}>
				<h4 className='header-underline'>{open}</h4>
				<PracticeDialogContent actionType={actionType} handleClose={handleClose} index={index} code={code} />
			</DialogContent>
			{/* <DialogActions>
				<Button onClick={handleClose} color='primary'>
					Cancel
				</Button>
				<Button onClick={handleSubmit} color='primary'>
					Submit
				</Button>
			</DialogActions> */}
		</Dialog>
	);
};
PracticeDialog.propTypes = {
	handleSubmit: PropTypes.func,
	handleClose: PropTypes.func,
	open: PropTypes.bool,
	code: PropTypes.string,
	actionType: PropTypes.string,
	index:PropTypes.any,

};

PracticeDialog.defaultProps = {
	open: false,
	code: '',
	actionType: 'Default',
	index:PropTypes.any,

};

export default React.memo(PracticeDialog);

const PracticeDialogContent = (props) => {
	const { actionType, code,index,handleClose } = props;
	console.log(index);

	switch (actionType) {
		case 'Discussion':
			return (
				<>
					<PracticeDialogDiscussion disabled code={code} />
				</>
			);
		case 'Actions':
			return (
				<>
					<PracticeDialogActions code={code} />
				</>
			);
		case 'File Attachments':
			return (
				<>
					<PracticeDialogFileAttachments index={index} code={code} />
				</>
			);
		default:
			return (
				<>
					<p>This is the defaut content.</p>
				</>
			);
	}
};

PracticeDialogContent.propTypes = {
	actionType: PropTypes.string,
	code: PropTypes.string,
	index: PropTypes.string,
	handleClose: PropTypes.func,


};
