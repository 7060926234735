import React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	makeStyles,
	Typography,
	Grid,
	Button,
	Divider,
	IconButton, DialogActions,
	Menu, TextField,
	MenuItem,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import GetFileAttachments from '../Helpers/AttachmentHelper';
import MoreVertical from '@material-ui/icons/MoreVert';
import { sendImage } from "../Store/Actions/data"
import { toast } from 'react-toastify'; 

const createRandomNumber = () => Math.random().toString(36).substring(7);

const useStyles = makeStyles((theme) => ({
	root: {},
	headerUnderline: {
		textDecoration: 'underline',
	},
	item: {
		margin: theme.spacing(1),
		padding: '5px',
	},
}));

const PracticeDialogFileAttachments = (props) => {
	const dispatch = useDispatch();
	const classes = useStyles();
	const [attachments, setAttachments] = useState(GetFileAttachments());

	const [uploadedFile, setUploadFile] = useState({});
	const [uploadedFile1, setUploadFile1] = useState({});
	const [uploadedFile2, setUploadFile2] = useState({});
	const [imageName, setImageName] = useState({imagename:""});

	// const [imageName, setImagename] = useState([]);

	const [anchorEl, setAnchorEl] = React.useState(null);
	const userData = useSelector((state) => state.data.userData)
const[isLoggedIn,setIslodding]=useState(true)
	const handleAttachmentView = () => { };

	const handleAttachmentDownload = () => { };

	const handleAttachmentDelete = () => { };

	const handleMenuOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const Security = useSelector((state) => state.data.images)

	const handleMenuClose = (event) => {
		setAnchorEl(null);
	};
	const handleChange = (e) => {
		setImageName((prev) => { return ({...prev, [e.target.id]:e.target.value}) })
		console.log(imageName);
	}
	const [active,setActive]=useState(true)
	const onFileChange = (event) => {
		// setUploadFile(event.target.files[0]);
		setUploadFile(event.target.files[0])
		console.log(uploadedFile!=={});
		if(uploadedFile!=={}){
			setActive(false)
		}
	};
	const onFileChange1 = (event) => {
		// setUploadFile(event.target.files[0]);
		setUploadFile1(event.target.files[0])
		console.log(event.target.files[0]);
	};
	const onFileChange2 = (event) => {
		// setUploadFile(event.target.files[0]);
		setUploadFile2(event.target.files[0])
		console.log(event.target.files[0]);
	};

	const handleDispatch = (e) => {
		let c=0;
		// console.log(Security);
		for(let i=0;i<Security.length;i++){
console.log("search");
			if(Security[i]["imagename"]===imageName.imagename){
				// console.log("present");
				c++;
			}
		}
		if(c!==0){
			toast.error("File Name Already Exist");
			return true
		}
		e.preventDefault()
				console.log(uploadedFile);
	if(imageName["imagename"]===""){
		toast.error("Enter File Name");
		return true
	}	
	if(!uploadedFile){
		toast.error("Please Select File");
		return true
	}
		dispatch(sendImage(uploadedFile, props.index, userData,imageName.imagename))
		setUploadFile([]);
		setImageName({"imagename":""})
		setActive(true)
	}
	const handleAttachment = () => {
		console.log(uploadedFile);
		if(!uploadedFile1){
			toast.error("Please Select File");
			return true
		}
		dispatch(sendImage(uploadedFile1, props.index, userData,"Security Policy Plan (SCP)"))
		setUploadFile1([]);

	}
	const handleAttachment1 = () => {
		console.log(uploadedFile, props.index, userData);

		if(!uploadedFile2){
			toast.error("Please Select File");
			return true
		}
		dispatch(sendImage(uploadedFile2, props.index, userData,"Plan of Actions and Milestones(POA&M)"))
		setUploadFile2([]);

	}
	const handleFileUpload = () => {
		if (!window?.document) {
			return;
		}
		let input = window.document.createElement('input');
		input.type = 'file';
		input.addEventListener('change', onFileChange);
		input.click();
	};
	const handleFileUpload1 = () => {
		if (!window?.document) {
			return;
		}
		let input = window.document.createElement('input');
		input.type = 'file';
		input.addEventListener('change', onFileChange1);
		input.click();
	};
	const handleFileUpload2 = () => {
		if (!window?.document) {
			return;
		}
		let input = window.document.createElement('input');
		input.type = 'file';
		input.addEventListener('change', onFileChange2);
		input.click();
	};

	React.useEffect(() => {
		if (uploadedFile !== null) {
			let { name, type } = uploadedFile;
			let id = createRandomNumber();
			setAttachments((attachments) => [
				...attachments,
				{ type: type || 'pdf', title: name, id },
			]);
		}
	}, [uploadedFile]);

	// 
	// const data = useSelector((state) => state.data.data)
	const SecurityCheck =(a)=>{
		
let c=0;
		// console.log(Security);
		for(let i=0;i<Security.length;i++){
console.log("search");
			if(Security[i]["imagename"]==="Security Policy Plan (SCP)"){
				// console.log("present");
				c++;
			}
		}
		if (c===0){
			return (
			<><Typography className={classes.item}>Security Policy Plan (SCP)</Typography>

			<Button
				variant='contained'
				className={classes.item}
				color='inherit'
				style={{ backgroundColor: "#00BFFF",marginRight: "-43%"  }}
				onClick={handleFileUpload1}>
				Upload
			</Button>
			<Button
				variant='contained'
				className={classes.item}
				color='inherit'
				style={{ backgroundColor: "green" }}
				onClick={handleAttachment}>
				Submit
			</Button> 
			</>)
		  }
		  else {
			return (<></>)
		  }
	}
	const PlanCheck =(a)=>{

		const Security = useSelector((state) => state.data.images)
		let c=0;
				// console.log(Security);
				for(let i=0;i<Security.length;i++){
		// console.log("search");
					if(Security[i]["imagename"]==="Plan of Actions and Milestones(POA&M)"){
						// console.log("present");
						c++;
					}
				}
		if (c===0){
			return (
			<>	<Typography className={classes.item}>Plan of Actions and Milestones(POA&M)</Typography>

			<Button
				variant='contained'
				className={classes.item}
				style={{ backgroundColor: "#00BFFF",marginRight: "-32%"  }}
				onClick={handleFileUpload2}>
				Upload
			</Button>
			<Button
				variant='contained'
				className={classes.item}
				color='inherit'
				style={{ backgroundColor: "green" }}
				onClick={handleAttachment1}>
				Submit
			</Button>
			</>)
		  }
		  else {
			return (<></>)
		  }
	}


	return (
<>

		{isLoggedIn ? (

<>

<Typography variant='h6' className={classes.headerUnderline}>
	Attachments
</Typography>
<Grid
	container
	className={classes.attachment}
	direction='column'
	justifyContent='flex-start'
	alignItems='center'>
	<Grid container justifyContent='space-between'>
	<React.Fragment id="a1" style={{ marginLeft: "10%" }}>{SecurityCheck(false)}</React.Fragment>


		
	</Grid>
</Grid>
<Grid
	container
	className={classes.attachment}
	direction='column'
	justifyContent='flex-start'
	alignItems='center'>
	<Grid container justifyContent='space-between'>

	<React.Fragment id="a2" style={{ marginLeft: "10%" }}>{PlanCheck(false)}</React.Fragment>


		

	
	</Grid>
</Grid>
{/* {attachments.map((attachment, index) => ( */}
<Grid
	// key={`${attachment.title}-${index}`}
	container
	className={classes.attachments}
	direction='column'
	justifyContent='flex-start'
	alignItems='center'>
	<Grid container direction={'row'} justifyContent='space-between'>
	<Typography className={classes.item}>Additional Attachments:</Typography>
<TextField onChange={handleChange} disabled={active} value={imageName["imagename"]} id="imagename" style={{ marginLeft: "-16%" }}  label="Enter file name" />
		<Button
			variant='contained'
			style={{ backgroundColor: "#00BFFF",marginRight: "-16%"  }}

			className={classes.item}
			onClick={handleFileUpload}>
			Upload
		</Button>
		<Button
			variant='contained'
			className={classes.item}
			color='inherit'
			style={{ backgroundColor: "green" }}
			onClick={(e)=>handleDispatch(e)}>
			Submit
		</Button>

	</Grid>
</Grid>
{/* ))} */}
<Divider component='hr' />


</>

							) : (
								""
							)}
			</>
	);
};
PracticeDialogFileAttachments.propTypes = {
	handleClose: PropTypes.func,

	index: PropTypes.any,

};
export default PracticeDialogFileAttachments;
