import access_controler from "../../Data/access-controler"
import awareness_traning from "../../Data/awareness-traning.json"
import audit_accountibility from "../../Data/audit-accountibility.json"
import configration_management from "../../Data/configration-management.json"
import identification_authentication from "../../Data/identification-authentication.json"
import incident_responce from "../../Data/incident-responce.json"
import maintenance from "../../Data/maintenance.json"
import media_protection from "../../Data/media-protection.json"
import personal_security from "../../Data/personal-security.json"
import security_accessment from "../../Data/security-accessment.json"
import security_informantion_intergration from "../../Data/security-informantion-intergration.json"
import system_communication_protection from "../../Data/system-communication-protection.json"
import recovery from "../../Data/recovery.json"
import risk_management from "../../Data/risk-management.json"
import physical_protechtion from "../../Data/physical-protechtion.json"
import { toast } from 'react-toastify'; 
import {registerNewUser,loginUser,imageSent,imageGet,deleteImage,saveSection,saveThisSection,pdfdataApi,resetDataApi} from "../../Service/api"
import jsPDF from "jspdf";



export const setData = (data) => {
	return {
		type: "DATA",
		data,
	}
};
export const updateData = (index,data) => {
	return {
		type: "UPDATEDATA",
		index,
		data,
	}
};
export const setLogin = (data) => {
	return {
		type: "LOGIN",
		data,
	}
};
export const setLogout = () => {
	localStorage.removeItem("user");		
	return {
		type: "LOGOUT",
	
	}
};
export const setImage = (data) => {
	return {
		type: "SETIMAGE",
		data,
	}
};
export const setPrintdata = (data) => {
	return {
		type: "SETDATA",
		data,
	}
};
export const setRegister = () => {
	return {
		type: "REGISTER",
	
	}
};
export const clearImage = () => {
	return {
		type: "CLEARIMAGE",
	
	}
};
export const clearReg = () => {
	return {
		type: "CLEARREG",
	
	}
};

export const setHeader = (header) => {
	return {
		type: "SETHEADER",
	header,
	}
};
export const addData = (data) => async (dispatch) => {
	try {
		dispatch(setPrintdata(data));
	}
	catch (e) {
console.log(e);
	}
};

export const setLoginAppdata = (data) => {
	return {
		type: "LOGIN",
		data,
	}
};
export const setCheckList = (data) => {
	return {
		type: "SETCHECKLIST",
		data,
	}
};
export const setDodValue = (data) => {
	return {
		type: "SETDOD",
		data,
	}
};

export const  saveThisSectionData = (data) =>async(dispatch)=>{
	try{
		const response = await saveThisSection(data)
		if(!response.error){
			toast.success(response.Message);
		//dispatch(setLogin(response.userid));
		}
		else{
			toast.error(response.Message);
		}
	}catch (e) {
		console.log(e);
	}
}


export const  saveSectionData = (data) =>async(dispatch)=>{
	try{
		const response = await saveSection(data)
		if(!response.error){
			toast.success(response.Message);
		//dispatch(setLogin(response.userid));
		}
		else{
			toast.error(response.Message);
		}
	}catch (e) {
		console.log(e);
	}
}



export const setReset = () => async (dispatch) => {
	try {
		const response = await resetDataApi()
		console.log(response);

		if(response.Msg==="Section Record"){
			window.location.reload();

		}
// 		if(!response.error){
		
// console.log(response.message);
// 			if(response.message==="Request failed with status code 401"){
// 				toast.error("Enter Registerd Email ID and Password");

// 			}
// 		}
	}
	catch (e) {
console.log(e);
	}
};



export const userLogin = (data) => async (dispatch) => {
	try {
		const response = await loginUser(data)
		console.log(!response.error);
		if(response.message==="Authentication Successful!"){
			toast.success("Login Successful");
		localStorage.setItem('user',(response.userid));
				localStorage.setItem('user',(response.userid));

		dispatch(setLogin(response.userid));
		}
		if(!response.error){
		
console.log(response.message);
			if(response.message==="Request failed with status code 401"){
				toast.error("Enter Registerd Email ID and Password");

			}
		}
	}
	catch (e) {
console.log(e);
	}
};


export const userLoginAppData = (data) => async (dispatch) => {
	try {
		// const response = await loginUser(data)
		console.log(data);
		//  const obj = JSON.parse(a)
		dispatch(setLoginAppdata(data));
		
	}

	catch (e) {
console.log("");
	}
};


export const register = (data) => async (dispatch) => {
	try {
		// var res = data.data["search_text"].substring(0, 1);

		const response = await registerNewUser(data)
		console.log(response);
		if (!response.error) {
			toast.success("Account Created");
			dispatch(setRegister());
		}
		if(!response.status){
		toast.error(response.Message);
	}
	if(response.error===true){
		if(response.errorMsg==="User already exist"){
			toast.error("User already exists !");
		}
	}

	} catch (e) {
	
		console.log(e);
	}
};


export const sendImage = (data,code,mail,name) => async (dispatch) => {
	try {
		const response = await imageSent(data,code,mail,name)
		dispatch(getImage(code))
		console.log(response);
		if (response.sucess) {
			toast.success("File submitted successfully!");
		}
		if(response.error)
		{
			toast.error("File Not Uploaded!");

		}

	} catch (e) {
	
		console.log(e);
	}
};


export const getImage = (data) => async (dispatch) => {
	try {
		const response = await imageGet(data)
		console.log(response.result);
		if(response.error===false){
			dispatch(setImage(response.result[0].image));
			dispatch(setCheckList(response.result[1].checklist));
			dispatch(setDodValue(response.result[2].sectioninfo));
		}
		if(response.error){
			dispatch(clearImage())
		}
	

	} catch (e) {
	
		console.log(e);
	}
};


export const imageDelete = (data) => async (dispatch) => {
	try {
		const response = await deleteImage(data)
		console.log(response);
		if (response.sucess) {
			toast.success("Attachment has been deleted");
		}
		if (response.error) {
			toast.error("Already deleted");
		}

	} catch (e) {
	
		console.log(e);
	}
};
export const pdfData = (data) => async (dispatch) => {
	try {
		const response = await pdfdataApi(data)

		if(response.Msg==="section userid not match"){
			toast.error("No Data Available");
			return true
		}
		console.log(response);
	
			dispatch(setPrintdata(response))
			let assessorpercentage;
        let assessor=0;
		let percentAssessor=0;
		let companyName= JSON.parse(localStorage.getItem('company_name')).name
		for(let i=0;i<response.length;i++){
			assessor=assessor+response[i].accesor
			percentAssessor=assessor/102
			assessorpercentage=percentAssessor;
			console.log(assessor,assessorpercentage*100,"assessor1");
		}
        var today = new Date();
var dd = String(today.getDate()).padStart(2, '0');
var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
var yyyy = today.getFullYear();

today = mm + '/' + dd + '/' + yyyy;


console.log(today);
			const unit = "pt";
		const size = "A4"; // Use A1, A2, A3 or A4
		const orientation = "portrait"; // portrait or landscape
	
		const marginLeft = 40;
		const marginTop = 40;
	
		const doc = new jsPDF(orientation, unit, size);
	
		doc.setFontSize(15);
	
		const company_name = "Company Name:";
		const report_date = "Self-Report Date:";
		const report_desc = "Summary of Self-Assessment Report:";
		const table_desc = " NIST SP 800-171 DoD Self-Assessment Report";
	
		const headers = [["102", "Total Score"]];
		const headers1 = [["Security Requirement", "Assessor Value", "DoD Value", "Comments/Recommendations"]];
		const data1=[
			{ name: assessor, profession: "Total Assessor Value" },
		
			{ name: (assessorpercentage*100).toFixed(2)+"%", profession: "Percentage of Compliant Controls" },
		  ];
	
		const databody = data1.map(elt => [elt.name, elt.profession]);
		const data2 = response.map(elt => [elt.section, elt.description, elt.accesor, elt.dod, elt.recommendation]);
	
		let content = {
		  theme:"grid",
		  startY: 150,
		  margin: { left: 200 },
		  tableWidth: 200,
		  head: headers,
		  body: databody,
		  columnStyles: {
			0: { cellWidth: 60, cellPadding: { left: 23 ,top:5},rowHeight: 22 },
			1: { cellWidth: 200, cellPadding: { left: 23 ,top:5},rowHeight: 22 },
		  },
		  headerStyles: {
			fillColor: "#FFA500",
			textColor: "FFFFFF",
			halign: 'center',
		 lineWidth: 1,
		lineColor: [0, 0, 0]
		},
		bodyStyles: {lineColor: [0, 0, 0]}
		};
	
		let content1 = {
		  tableLineColor: [189, 195, 199],
		  tableLineWidth: 0.75,

		  columnStyles: {
			0: { cellWidth: 220, cellPadding: { left: 50 ,top:5},rowHeight: 30 },
			1: { cellWidth: 60, cellPadding: { left: 10 ,top:5} ,rowHeight: 30 },
			2: { cellWidth: 60, cellPadding: { left: 20 ,top:5} ,rowHeight: 30 },
			3: { cellWidth: 175, cellPadding: { left: 20,top:5 },rowHeight: 30  },
		  },
	
		  headStyles:{
	fillColor:"#FFFFFF",
	textColor:"#000",

		  },
		  theme: "grid",
		  startY: 360,
		  body: headers1,
		};
		let content2 = {
		  tableLineColor: [189, 195, 199],
		  tableLineWidth: 0.75,
		  theme:"grid",
		  
		  columnStyles: {
			0: { cellWidth: 100, cellPadding: { left: 10 } },
			1: { cellWidth: 120, cellPadding: { left: 10 } },
			2: { cellWidth: 60, cellPadding: { left: 20 } },
			3: { cellWidth: 60, cellPadding: { left: 20 } },
			4: { cellWidth: 175, cellPadding: { left: 10 } },
	
		  },
		  startY: 390,
		  body: data2
		};
	
		doc.text(company_name, marginLeft, 40);
		doc.text(companyName, 160, 40);
		doc.text(report_date, marginLeft, 60);
		doc.text(today, 160, 60);
		doc.text(report_desc, marginLeft, 100);
		doc.autoTable(content);
		doc.text(table_desc, 150, 330);

		doc.autoTable(content1);
		doc.autoTable(content2);
		doc.setLineWidth(1)
	
		// doc.autoTable(content1,marginTop);
		doc.save("Assessment Report.pdf")
		// if (response.error) {
		
		// }

	} catch (e) {
	
		console.log(e);
	}
};



export const pageData = (data) => async (dispatch) => {

	try {

		console.log(data);
		if (data === "AC") {
			dispatch(setHeader("Access Control (AC)"));
			dispatch(setData(access_controler));
		}
		if (data === "AT") {
			dispatch(setHeader(" Awareness & Training (AT)"));

			dispatch(setData(awareness_traning));
		}
		if (data === "AU") {
			dispatch(setHeader("Audit & Accountability (AU)"));

			dispatch(setData(audit_accountibility));
		}
		if (data === "CM") {
			dispatch(setHeader("Configuration Management (CM)"));

			dispatch(setData(configration_management));
		}
		if (data === "IA") {
			dispatch(setHeader("Identification & Authentication (IA)"));

			dispatch(setData(identification_authentication));
		}
		if (data === "IR") {
			dispatch(setHeader("Incident Response (IR)"));

			dispatch(setData(incident_responce));
		}
		if (data === "MA") {
			dispatch(setHeader("Maintenance (MA)"));

			dispatch(setData(maintenance));
		}
		if (data === "MP") {
			dispatch(setHeader("Media Protection (MP)"));

			dispatch(setData(media_protection));
		}
		if (data === "PS") {
			dispatch(setHeader("Personnel Security (PS)"));

			dispatch(setData(personal_security));
		}
		if (data === "CA") {
			dispatch(setHeader("Security Assessment (CA)"));

			dispatch(setData(security_accessment));
		}
		if (data === "SI") {
			dispatch(setHeader("System & Information Integrity (SI) "));

			dispatch(setData(security_informantion_intergration));
		}
		if (data === "SC") {
			dispatch(setHeader("System & Communications Protection (SC) "));

			dispatch(setData(system_communication_protection));
		}
		if (data === "RE") {
			dispatch(setHeader("Recovery (RE)"));

			dispatch(setData(recovery));
		}
		if (data === "RM") {
			dispatch(setHeader("Risk Management (RM)"));

			dispatch(setData(risk_management));
		}
		if (data === "PE") {
			dispatch(setHeader("Physical Protection (PE)"));

			dispatch(setData(physical_protechtion));
		}
	}
	catch (e) {
		console.log("");
	}
};

