class Domain {
	constructor() {
		this.id = 0;
		this.name = '';
		this.abbreviation = '';
	}

	toJson() {
		return `{id: "${this.id}", name: "${this.name}", abbreviation:"${this.abbreviation}"}`;
	}
}

export default Domain;
