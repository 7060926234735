import React from "react";
import { Switch, Route, Redirect,useHistory } from "react-router-dom";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import Home from "../PracticeView/_PracticeView"
import Login from "../Pages/LoginPage"
import Register from "../Pages/RegistrationPage"
import {userLoginAppData} from "../Store/Actions/data"
import { useDispatch, useSelector } from 'react-redux'
import severPath from "../config.json"

function Layout(props) {

	
	const history =useHistory();
const dispatch=useDispatch()

let user = JSON.parse(localStorage.getItem('user_info'));




	const myArr = window.location.href.split("/");
	console.log(myArr,"window.location.href");
	for(let i=0;i<myArr.length;i++){
		if(user!==null){
		if(myArr[i]==="login"){
			console.log("login");
			window.location.assign(severPath.SERVER_URL+"/dashboard")
			dispatch(userLoginAppData(localStorage.getItem('user')))
		}
	}

	}
	// const { isLoggedIn, logout } = props;
	const classes = ""
    // useStyles();
    const isLoggedIn = useSelector((state) => state.data.isLoggedIn)
console.log(isLoggedIn);
	return (
		<div className={classes.root}>
			{/* <div>{isLoggedIn ? <Sidebar  /> : ""}</div> */}
			<div>
				{isLoggedIn ? (

<div>
				

					</div>
				) : (
					""
				)}
				<Switch>
			<div >
					<PrivateRoute
						path={"/dashboard"}
						isLogin={isLoggedIn}
						component={<Home />}
					/>
						<PublicRoute
						path={"/login"}
						isLogin={isLoggedIn}
						component={<Login />}
					/>
					<PublicRoute
						path={"/register"}
						isLogin={isLoggedIn}
						component={<Register />}
					/>

					<Route render={() => <Redirect to="/dashboard" />} />
					</div>
				</Switch>

			</div>
		</div>
	);
}


export default Layout;

