import React, {  useEffect, useState } from 'react';
import {
  withStyles,
  makeStyles,
} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { Button, Container } from '@material-ui/core';
import { useHistory } from 'react-router';
import "./LoginPage.css"
import { Link } from 'react-router-dom';
import {userLogin} from '../Store/Actions/data'
import { useDispatch,useSelector } from 'react-redux'
import { toast } from 'react-toastify'; 
import MainHeader from '../mainHeader';

// import Snackbar from '@material-ui/core/Snackbar';
// import MuiAlert from '@material-ui/lab/Alert';


// function Alert(props) {
//     return <MuiAlert elevation={6} variant="filled" {...props} />;
//   }
  
const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'black',
      fontWeight:'bold'
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#84C5A3',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.2)',
      },
      '&:hover fieldset': {
        borderColor: 'yellow',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#84C5A3',
      },
    },

  },
  
 
})(TextField);


const useStyles = makeStyles((theme) => ({
  root: {
      marginTop:"15%",
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },

   
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
 
  margin: {
    margin: theme.spacing(1),
    borderColor: 'green',
  },
  heading:{
    padding:'15px 25px',
    borderBottom: '1px solid rgba(0,0,0,.1)',
    display:'block',
    width:'100%',
    textAlign:'left',
    fontWeight:600,
    fontSize:18,
    marginBottom:30,
  },
  mxauto:{
    margin:'auto'
  },
  Link:{
    textTransform:'uppercase',
    fontSize:'12px',
    color:'#000',
    textDecoration:'none',
    transitions:'all .4s',
    '&:hover': {
      color: '#84C5A3',
      fontWeight:'bold'
    },
   
  },
  locationtxt:{
    fontSize:'12px',
    fontWeight:'500',
    color:'000',
    textTransform:'uppercase'
  },
  accountContainer:{
       maxWidth:'535px',
    marginLeft:'80px',
    [theme.breakpoints.down("sm", "md")]: {
      marginLeft:'0px',
    },
  },
  btn:{
    backgroundColor:"#84C5A3", width:"450px", display:'flex', marginTop:'50px',
    [theme.breakpoints.down("sm", "md")]: {
      width:"100%",
    },
  },
  formwrap:{
    padding:'10px 50px 20px',
    [theme.breakpoints.down("sm", "md")]: {
      padding:'0px 20px 10px',
    },
  },

}));




export default function CustomizedInputs() {
  const classes = useStyles();
  const [values, setValue] = useState({
    user_name: "", password: ""
  });
  const [open, setOpen] = React.useState(false);
  const [text, setText] = React.useState("");
  const [open1, setOpen1] = React.useState(false);
  const [text1, setText1] = React.useState("");
  const handleChange = (e) => {
    setOpen1(false);
    setText1("");
    setOpen(false);
    setText("");
    let id = e.target.id;
    let value = e.target.value
    console.log(value, id);
    setValue((prev) => { return ({ ...prev, [id]: value }) })
    console.log(values);
  }
  const history=useHistory();
  const isLoggedIn = useSelector((state) => state.data.isLoggedIn)

useEffect(()=>{
  console.log(isLoggedIn);
  if(isLoggedIn){
    history.push("/dashboard");
  }
},[isLoggedIn])
const dispatch = useDispatch();
const handleRegister=(e)=>{
  e.preventDefault();
    setOpen(false);
    setText("");
    setOpen1(false);
    setText1("");

//     let user = JSON.parse(localStorage.getItem('user_info'));
//     console.log("user",user);
//     if(user!==null){
// console.log("condition pass");
// toast.error("Please Logout previos session");
// return true
//     }
    let filter = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
       if (!filter.test(values.user_name)) {
           console.log("error");
           toast.error("Enter Valid Email");
        // setOpen(true);
        // setText("Incorrect Email");
        history.push("/login");
    return true;
 }
 if (values.password===""&&filter.test(values.user_name)) {
  toast.error("Enter Password");
// setOpen1(true);
// setText1("Enter Password");
history.push("/login");
return true;
}
if (values.password!==""&&filter.test(values.user_name)) {
  
console.log("check login");
  dispatch(userLogin(values))
 

  }
    }
    const RegisterPage = () => {
      history.push("/register");
    }
  return (
<>
        <Grid container spacing={0}>
		<Grid item xs={12}>
			<MainHeader />
		</Grid>
    </Grid>
          <div id="div1">

    <Container style={{ maxWidth: "650px", margin: '0 auto', marginTop:"15%" }}>
      <form className={classes.root} noValidate >
        <Card  style={{marginTop:"25%"}} className={classes.root}>
          <Typography  className={classes.heading}>
            Login
            <CssBaseline />

          </Typography>



          <Grid container spacing={3} className={classes.formwrap}>
            <Grid item xs={12}>
              <CssTextField style={{ borderColor: "black", width: "80%" }}
                className={classes.margin}
                label="Email"
                id="user_name"
                error={open}
                helperText={text}
                variant="outlined"
                onChange={handleChange}
              // id="custom-css-outlined-input"
              />
            </Grid>
            <Grid item xs={12}>
              <CssTextField style={{ borderColor: "black", width: "80%" }}
                className={classes.margin}
                label="Password"
                type="password"
                error={open1}
                helperText={text1}
                onChange={handleChange}
                id="password"
                variant="outlined"
              // id="custom-css-outlined-input"
              />
            </Grid>

            <Grid xs={12} alignItems="center">
         <Button variant="contained" onClick={handleRegister} style={{ backgroundColor: "#e69832", marginBottom: "8%", marginTop: "2%", width: '150px', fontSize: '16px', paddingTop: '10px' }}>
              Login
            </Button></Grid>
          </Grid>


        </Card>
        <p className={classes.mxauto}><Link onClick={RegisterPage} className={classes.Link}>Create an account</Link></p>
      </form>
      {/* <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <Alert severity="error">This is an error message!</Alert>
      </Snackbar>
      */}
    </Container>

   

    </div>
    </>
  );
}
