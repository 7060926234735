import React, { useEffect } from 'react';
import logo from './logo.png';
import jsPDF from "jspdf";
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { Button, Modal, Fade, Select, Tooltip, Card } from '@material-ui/core';
import { useHistory } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import {setLogout,pdfData,setReset} from './Store/Actions/data'
import { useDispatch } from 'react-redux';
import Backdrop from '@material-ui/core/Backdrop';

const useStyles = makeStyles((theme) => ({
	formControl: {
	  margin: theme.spacing(1),
	  minWidth: 120,
	},
	selectEmpty: {
	  marginTop: theme.spacing(2),
	},
  }));
const MainHeader = () => {


	const Data = useSelector((state) => state.data.printData)
	const isLoggedIn = useSelector((state) => state.data.isLoggedIn)

	const [data1, Setdata] = useState([
		{ name: "-8", profession: "Total Point Deduction" },
		{ name: "2", profession: "Number of Deficient Controls" },
		{ name: "98%", profession: "Percentage of Compliant Controls" },
	
	  ])
useEffect(()=>{
console.log(Data);
},[Data])
const handleDistroy=()=>{
	dispatch(setReset())
}
	const handleDownload = () => {
		
		dispatch(pdfData(),()=>{
			console.log('hello');
		})

	  };
	  const classes = useStyles();
	  const [state, setState] = React.useState({
		age: '',
		name: 'hai',
	  });

	const history = useHistory();
const dispatch=	useDispatch();
const  handleLogout =()=>{
	history.push("/login");
	dispatch(setLogout())
	localStorage.removeItem("user_info");
	localStorage.removeItem("company_name");

}
const [open,setOpen]=useState(false)
const handleClose = () => {
    setOpen(false);
  };

const handleReset=()=>{
	if(open===false){
		setOpen(true)

	}

}
	return (
		<div className='App-header'>
			<img			
				src={logo}
				alt='company logo'
				style={{ margin: '10px', width:'200px' }}
			/>
			<div className='title-center'>
				<p>Welcome to AVIRTEK Cybersecurity Maturity Model Certification (CMMC) Service</p>
			</div>
			{isLoggedIn ? (

<><Button style={{backgroundColor:"#D22B2B",marginRight:"1%",color:"white"}} onClick={handleReset}>Reset</Button>
<Tooltip title="Assessment Report" aria-label="Assessment Report">				

<Button style={{backgroundColor:"green",marginRight:"1%" ,color:"white",fontStyle:"normal" }}  onClick={handleDownload}>Report</Button></Tooltip>
<Button style={{backgroundColor:"#D22B2B",marginRight:"1%",color:"white"}} onClick={handleLogout}>Logout</Button>



<Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open} style={{backgroundColor:"#D3D3D3",width:"400px",height:"170px",marginLeft:"40%",marginTop:"20%"}}>
		<Card >
          <h2 style={{textAlign:"center"}} id="transition-modal-title">Do you want to Reset all data?</h2>
			<div style={{textAlign:"center",paddingTop:"40px"}}>
           <Button  style={{backgroundColor:"green",color:"white"}}    onClick={handleDistroy} >Confirm</Button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
		   <Button   style={{backgroundColor:"#D22B2B",color:"white"}}   onClick={handleClose} >Cancel</Button>
		   </div>
          </Card>
        </Fade>
      </Modal>
					</>
				) : (
				<>
			
				</>
				)}
	

		</div>
	);
};

export default MainHeader;
