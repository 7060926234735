import axios from 'axios'
import { toast } from 'react-toastify'; 

var qs = require('qs');
var FormData = require('form-data');

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
//const URL="http://3.17.207.43:9000"
const URL="https://cmmc.avirtek.com"

export const registerNewUser = (a) => {   
    console.log("api call",a); 
var data = qs.stringify({
    'name': a['firstName'],
    'email':a['user_name'],
    'password':a['password'],
   
  });
  var config = {
    method: 'post',
    url: URL+'/signup',
    headers: { 
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data : data
  };
  return axios(config)
  .then(function (response) {
    console.log(JSON.stringify(response.data));
    return response.data
  })
  .catch(function (error) {
    console.log(error);
  });
}

//section info save

export const saveThisSection = (data) => {   
  console.log("api call",data); 
  let headers= { 
    'Content-Type': 'application/x-www-form-urlencoded'
  };
  let user = JSON.parse(localStorage.getItem('user_info'));
  headers['Authorization'] = user.token;
  var config = {
  method: 'post',
  url: URL+'/save-section-info',
  headers,
  data:qs.stringify(data)
};
return axios(config)
.then(function (response) {
  return response.data
})
.catch(function (error) {
  console.log(error);
});
}

export const saveSection = (data) => {   
  console.log("api call",data); 
  let headers= { 
    'Content-Type': 'application/x-www-form-urlencoded'
  };
  let user = JSON.parse(localStorage.getItem('user_info'));
  headers['Authorization'] = user.token;
  var config = {
  method: 'post',
  url: URL+'/save-check-list',
  headers,
  data:qs.stringify(data)
};
return axios(config)
.then(function (response) {
  return response.data
})
.catch(function (error) {
  console.log(error);
});
}

export const imageSent = (data1,code,mail,name) => {   
  console.log("api call",data1,code,mail,name); 
  let formData = new FormData(); // instantiate it
  formData.append('image', data1);
  //formData.append('userid', 38);
  formData.append('section_id', code);
  formData.append('imagename', name);
  let user = JSON.parse(localStorage.getItem('user_info'));
var config = {
  method: 'post',
  url: URL+'/upload',
 data: formData,

  headers: { 
      'Content-Type': 'multipart/form-data',
      'Authorization': user.token

  },
  // files:formData,
};

return axios(config)
.then(function (response) {
  console.log(JSON.stringify(response.data));
  return response.data
})
.catch(function (error) {
  console.log(error);
});
}

// export const imageSent = (data1,code,mail,name) => {   
//   console.log("api call",data1,code,name); 
  
//   let headers= { 
//     'Content-Type': 'application/x-www-form-urlencoded',
//     'Content-Type': 'multipart/form-data'
//   }

//   let user = JSON.parse(localStorage.getItem('user_info'));
//   headers['Authorization'] = user.token;

  
//   let formData = new FormData(); // instantiate it
//   formData.append('image', data1);
//   formData.append('section_id', code);
//   formData.append('imagename', name);

// // var data = ({
// //   'userid':mail,
// //   'section_id':code,
// //   'image':data1,
 
// // });
// console.log("formData",formData);
// var config = {
//   method: 'post',
//   url: URL+'/upload',
//  data: formData,
//  headers:headers
// };

// console.log("config",config)

// return axios(config)
// .then(function (response) {
//   console.log("ssssssss",JSON.stringify(response.data));
//   return response.data
// })
// .catch(function (error) {
//   console.log(error);
// });
// }


export const imageGet = (a) => {   
console.log(a);

 var userId = localStorage.getItem('user');
  //  var sectionId = '3.1.1*{AC.1.001}';
  let headers= { 
    'Content-Type': 'application/x-www-form-urlencoded',
  }

  let user = JSON.parse(localStorage.getItem('user_info'));
  headers['Authorization'] = user.token;

   var config = {
     method: 'get',
     url: URL+`/getimages?userid=${userId}&section_id=${a}`,    
     headers
   };
  return axios(config)
   .then(function (response) {
    return response.data
   })
   .catch(function (error) {
     console.log(error);
     if(error){
      toast.error("Some error occured");
     }
   });
}


export const resetDataApi = () => {   

  let headers= { 
    'Content-Type': 'application/x-www-form-urlencoded',
  }

  let user = JSON.parse(localStorage.getItem('user_info'));
  headers['Authorization'] = user.token;
  var data = new FormData();
var config = {
  method: 'get',
  url: URL+'/resetimages',
  
   headers,
    
  
  data : data
};
return axios(config)
.then(function (response) {
   return response.data
})
.catch(function (error) {
  console.log(error);
});
  }


  export const deleteImage = (a) => {   
    console.log(a);
    
     var userId = localStorage.getItem('user');
      //  var sectionId = '3.1.1*{AC.1.001}';
       var config = {
         method: 'get',
         url: URL+`/deleteimages?userid=${userId}&image_id=${a}`,
         headers: { 
           'Content-Type': 'application/x-www-form-urlencoded',
    
         },
        
       };
      return axios(config)
       .then(function (response) {
        return response.data
       })
       .catch(function (error) {
         console.log(error);
       });
    }




export const loginUser = (a) => {   
    console.log(a);
    var data = qs.stringify({
        'email': a['user_name'],
        'password': a['password'],
      });
      console.log(data);
      var config = {
        method: 'post',
        url: URL+'/signin',
        headers: { 
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data : data
      };
      return axios(config)
      .then(function (response) {
        if(response.data.token){
         
          let user = {
            token:response.data.token
          }
          let company = {
            name:response.data.name
          }
          localStorage.setItem('user_info',JSON.stringify(user));
          localStorage.setItem('company_name',JSON.stringify(company));
        }
        return response.data
      })
      .catch(function (error) {
        return error
      });
}
export const pdfdataApi = (a) => {   
  var userId = localStorage.getItem('user');
  //  var sectionId = '3.1.1*{AC.1.001}';
  let headers= { 
    'Content-Type': 'application/x-www-form-urlencoded',
  }

  let user = JSON.parse(localStorage.getItem('user_info'));
  headers['Authorization'] = user.token;

   var config = {
     method: 'get',
     url:URL+'/get-section-info',    
     headers
   };
  return axios(config)
   .then(function (response) {
    return response.data
   })
   .catch(function (error) {
     console.log(error);
     if(error){
      toast.error("Some error occured");
     }
   });
}
