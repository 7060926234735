import AttachmentModel from '../Models/AttachmentModel';

function getAttachments() {
	var staticAttachments = [];

	// var attachment1 = new AttachmentModel();
	// attachment1.file = 'This should be actual file data';
	// attachment1.title = 'Print Security Policy Plan (SCP)';
	// attachment1.type = 'pdf';
	// staticAttachments.push(attachment1);

	// var attachment2 = new AttachmentModel();
	// attachment2.file = 'This should be actual file data';
	// attachment2.title = 'Print Plan of Actions and Milestones(POA&M)';
	// attachment2.type = 'pdf';
	// staticAttachments.push(attachment2);

	// var attachment3 = new AttachmentModel();
	// attachment3.file = 'This should be actual file data';
	// attachment3.title = 'Attachment3';
	// attachment3.type = 'pdf';
	// staticAttachments.push(attachment3);

	return staticAttachments;
}

export default getAttachments;
