import PropTypes from 'prop-types';
import React from 'react';
import clsx from 'clsx';
import {
	Paper,
	Grid,
	Toolbar,
	Tooltip,
	IconButton,
	makeStyles,
} from '@material-ui/core';
import "jspdf-autotable";
import { useState } from 'react';
import GetAppIcon from '@material-ui/icons/GetApp';
import { default as SaveIcon } from '@material-ui/icons/Save';
import { default as ActionIcon } from '@material-ui/icons/NoteAdd';
import { default as DiscussionIcon } from '@material-ui/icons/Message';
import { default as AttachmentIcon } from '@material-ui/icons/AttachFile';
import jsPDF from "jspdf";
import { toast } from 'react-toastify'; 

import { default as PracticeDialog } from './_PracticeDialog';
import { useHistory } from 'react-router-dom';
import {addData,updateData,pdfData} from '../Store/Actions/data';
import { useDispatch,useSelector } from 'react-redux';
const debug = false;

const usePracticeActionStyles = makeStyles((theme) => ({
	root: {
		color: theme.palette.common.black,
		'&$isSaved': {
			color: theme.palette.error.main,
		},
	},
	isSaved: {},
	footer: {
		backgroundColor: 'grey !important',
	},
}));

const PracticeAction = (props) => {
	const {
		title,
		placement,
		handleClick,
		isSaved,
		Icon,
		actionType,
		code,
	} = props;
	const classes = usePracticeActionStyles();
	return (
		<Tooltip title={title} placement={placement}>
			<IconButton
				name={`${code}-${actionType}`}
				onClick={handleClick}
				aria-describedby={actionType}
				code={code}
				actionType={actionType}>
				<Icon
					className={clsx(classes.root, {
						[classes.change]: isSaved,
					})}
				/>
			</IconButton>
		</Tooltip>
	);
};

PracticeAction.propTypes = {    
	Icon: PropTypes.any,
	code: PropTypes.string,
	handleClick: PropTypes.func,
	isSaved: PropTypes.bool,
	placement: PropTypes.string,
	title: PropTypes.string,
	actionType: PropTypes.oneOf([
		'Discussion',
		'Actions',
		'File Attachments',
		'Save',
	]),
};

PracticeAction.defaultProps = {
	placement: 'bottom',
	title: 'Save',
	Icon: SaveIcon,
	code: '',
};
PracticeAction.downloadProps = {
	placement: 'bottom',
	title: 'Download',
	Icon: SaveIcon,
	code: '',
};
const practiceCommonActions = [
	
	{
		title: 'File Attachments',
		Icon: AttachmentIcon,
		actionType: 'File Attachments',
	},
	// { title: 'Discussion', Icon: DiscussionIcon, actionType: 'Discussion' },
];

const PracticeToolbar = (props) => {
	const[printData,setPrintData]=useState([]);

	const classes = usePracticeActionStyles();
	const { code,code1, isSaved, setIsSaved ,index,data} = props;
// console.log(index);
	const [open, setOpen] = React.useState(false);
	const [actionType, setActionType] = React.useState('');

	const handleToggleDialog = () => setOpen(!open);

	const handleDialogSubmit = () => {
		if (isSaved) {
			setIsSaved(false);
		}
		setOpen(!open);
	};

	const output = (event) => {
		alert(
			event.currentTarget.attributes['aria-describedby'].value +
				':  ' +
				code +
				'\r\n' +
				event.currentTarget.name +
				'\r\n'
		);
	};

	const handlePracticeWork = (actionType = 'default') => (event) => {
		setActionType(actionType);
		if (debug === true) {
			output(event);
		}

		if (!open) {
			setOpen(true);
		}
	};



	const styles = {
	  fontFamily: "sans-serif",
	  textAlign: "center"
	};
	const colstyle = {
	  width:"auto"
	};
	const tableStyle = {
	  width: "100%"
	};

const dispatch=	useDispatch();
const Data = useSelector((state) => state.data.printData)

	  const history =useHistory();


	  const handleSave = (data,data2,comment) => {

		let code = data2.split("*");

		console.log("Data",comment.dod_value);
	if(!comment.dod_value){
		toast.error("DoD Value is requried");
		return true
	}
		  if(parseInt(comment.dod_value)<0 || parseInt(comment.dod_value)>9 || comment.dod_value===""||isNaN(comment.dod_value)){
			toast.error("DOD Value must be a single digit positive numeric value");
			  return true
		  }
		  if(!comment.assessor_value){
			toast.error("Assessor Value is requried");
			return true
		}
		  if(parseInt(comment.dod_value)<parseInt(comment.assessor_value) || parseInt(comment.assessor_value)<0||isNaN(parseInt(comment.assessor_value))){
			toast.error("Accessor Value cannot be less than zero or greater than entered DOD value.");
			return false
		  }
		  if(comment.dod_value===""){
			toast.error("Accessor Value cannot be less than zero or greater than entered DOD value.");
			return false
		  }
		  
		  props.saveData(index);


		let value ={ security_code: code[0], security:data.desc, assessor: comment.assessor_value, dod:comment.dod_value, comments: comment.assessor_recommendations }
	
		for(let i=0;i<Data.length;i++){
			if(Data[i]['security_code']===code[0]){
				dispatch(updateData(i,value))
				toast.success("Data Updated Successfully!");
				return true
			}	
		
		}
			setPrintData((prev)=>{return({...prev,value})})
			dispatch(addData(value))
			toast.success("Data Added Successfully!");

		
		
	  };
	  const [data1, Setdata] = useState([
		{ name: "-8", profession: "Total Point Deduction" },
		{ name: "2", profession: "Number of Deficient Controls" },
		{ name: "98%", profession: "Percentage of Compliant Controls" },
	
	  ])
	
	  const handleDownload = () => {
		dispatch(pdfData())
	// 	//get-section-info api call 
	// 	//http://3.17.207.43:9000/get-section-info?section=3.1.1*[AC.1.001]		
		
	// 	const unit = "pt";
	// 	const size = "A4"; // Use A1, A2, A3 or A4
	// 	const orientation = "portrait"; // portrait or landscape
	
	// 	const marginLeft = 40;
	// 	const marginTop = 40;
	
	// 	const doc = new jsPDF(orientation, unit, size);
	
	// 	doc.setFontSize(15);
	
	// 	const company_name = "Company Name:";
	// 	const report_date = "Self-Report Date:";
	// 	const report_desc = "Summary of Self-Assessment Report:";
	// 	const table_desc = "NIST SP 800-171 DoD Self-Assessment Report";
	// 	const headers = [["102", "Total Score"]];
	// 	const headers1 = [["Security Requirement", "Assessor Value", "DoD Value", "Comments/Recommendations"]];
	
	
	// 	const data = data1.map(elt => [elt.name, elt.profession]);
	// 		const data2 = (Data[0])?Data[0].map(elt => [elt.section, elt.description, elt.accesor, elt.dod, elt.recommendation]):null;
	
	// 	let content = {
	// 	  theme:"grid",
	// 	  startY: 150,
	// 	  margin: { left: 200 },
	// 	  tableWidth: 200,
	// 	  head: headers,
	// 	  body: data,
	// 	  headerStyles: {
	// 		fillColor: "#385FC7",
	// 		textColor: [0, 0, 0],
	// 		halign: 'center',
	// 	 lineWidth: 1,
	// 	lineColor: [0, 0, 0]
	// 	},
	// 	bodyStyles: {lineColor: [0, 0, 0]}
	// 	};
	
	// 	let content1 = {
	// 	  tableLineColor: [189, 195, 199],
	// 	  tableLineWidth: 0.75,
	// 	  columnStyles: {
	// 		0: { cellWidth: 140, cellPadding: { left: 10 ,top:5},rowHeight: 22 },
	// 		1: { cellWidth: 90, cellPadding: { left: 10 ,top:5} },
	// 		2: { cellWidth: 90, cellPadding: { left: 20 ,top:5} },
	// 		3: { cellWidth: 195, cellPadding: { left: 40,top:5 } },
	// 	  },
	
	// 	  headStyles:{
	// fillColor:"#FFFFFF",
	// textColor:"#000",
	
	// 	  },
	// 	  theme: "grid",
	// 	  startY: 360,
	// 	  body: headers1,
	// 	};
	// 	let content2 = {
	// 	  tableLineColor: [189, 195, 199],
	// 	  tableLineWidth: 0.75,
	// 	  theme:"grid",
		  
	// 	  columnStyles: {
	// 		0: { cellWidth: 40, cellPadding: { left: 10 } },
	// 		1: { cellWidth: 100, cellPadding: { left: 10 } },
	// 		2: { cellWidth: 90, cellPadding: { left: 40 } },
	// 		3: { cellWidth: 90, cellPadding: { left: 40 } },
	// 		4: { cellWidth: 195, cellPadding: { left: 70 } },
	
	// 	  },
	// 	  startY: 382,
	// 	  body: data2
	// 	};
	
	// 	doc.text(company_name, marginLeft, 40);
	// 	doc.text(report_date, marginLeft, 60);
	// 	doc.text(report_desc, marginLeft, 100);
	// 	doc.autoTable(content);
	// 	doc.text(table_desc, 150, 330);
	// 	// doc.text(table_desc1, 180, 345);
	// 	doc.autoTable(content1);
	// 	doc.autoTable(content2);
	// 	doc.setLineWidth(1)
	
	// 	// doc.autoTable(content1,marginTop);
	// 	doc.save("Assessment Report.pdf")
	  };
	return (
		<Paper className={classes.footer}>
			<Toolbar>
				<Grid
					container
					direction='row'
					justifyContent='flex-end'
					alignItems='center'>
					<PracticeAction
						key={`${code}-save`}
						handleClick={()=>handleSave(props.data,props.index,props.code1)}
						isSaved={isSaved}
						actionType='Save'
						code={code}
						{...props}
					/>
					<PracticeAction
						handleClick={handleDownload}
						placement= 'bottom'
						title= 'Download'
						Icon={GetAppIcon}
						code=''
					/>
					{practiceCommonActions.map((commonProps, index) => (
						<PracticeAction
							handleClick={handlePracticeWork(commonProps.actionType)}
							key={`${code}-${commonProps.actionType}`}
							name={`${code}-${commonProps.actionType}`}
							code={code}
							{...commonProps}
						/>
					))}
				</Grid>
			</Toolbar>
			<PracticeDialog
			index={index}
				open={open}
				code={code}
				handleClose={handleToggleDialog}
				handleSubmit={handleDialogSubmit}
				actionType={actionType}
				{...props}
			/>
		</Paper>
	);
};

PracticeToolbar.propTypes = {
	isSaved: PropTypes.bool.isRequired,
	code: PropTypes.any,
	code1: PropTypes.any,
	setIsSaved: PropTypes.any,
	data:PropTypes.any,
	index:PropTypes.any,
	saveData:PropTypes.any
};

export default React.memo(PracticeToolbar);
