import React from 'react';
import './App.css';
import Login from './Pages/LoginPage'
import SaveData from './Pages/SaveData'
import RegisterPage from './Pages/RegistrationPage'
import { BrowserRouter as Router } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import { PracticeView } from './PracticeView';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from './Routes/Layout';
import { useDispatch } from 'react-redux';
import { userLoginAppData,setLogout } from './Store/Actions/data';
export default function App() {
	document.title = 'Avirtek CMMC Manager';


	const dispatch = useDispatch();
	const data = localStorage.getItem('user_info') 
  

	window.addEventListener('storage', e => {
	

  
		if(e.key === 'user' && e.oldValue && !e.newValue) {
		  dispatch(setLogout());
		 }
	 
	 });
  
	if (localStorage.getItem('user') !== null) {

	  dispatch(userLoginAppData(localStorage.getItem('user')))
  
  } else {
	  // console.log(`Email address not found`);
  }
	return (
		
		<div className='App' >
			  <ToastContainer position="top-center" autoClose={2500} />
			<div id='mainContainer' className='container'>
				<Router>
								<Layout/>
						</Router>
			</div>
		</div>
	);
}
