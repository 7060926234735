import React, { Fragment, useEffect } from 'react';
import { useState } from 'react';
import {
	
	makeStyles,

} from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import {pageData} from "./Store/Actions/data"
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'; 

import MenuHelper from './Helpers/MenuHelper';
const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		textAlign: 'start',
		alignItems: 'center',
	},
	'& label.Mui-focused': {
		color: 'black',
	  },
	  '& .MuiInput-underline:after': {
		borderBottomColor: 'black',
	  },
	  '& .MuiOutlinedInput-root': {
		'& fieldset': {
		  borderColor: 'black',
		},
		'&:hover fieldset': {
		  borderColor: 'black',
		},
		'&.Mui-focused fieldset': {
		  borderColor: 'black',
		},
		'&.MuiTypography-h5':{
			color:'#fff'
		}
		 },
	active:{
		   background: '#ffa500'
		 }

}));
const ListItemLink = (props) => {
	return <ListItem button component='a' {...props} />;
};

const MainMenu = () => {
	const staticMenuData = new MenuHelper().getStaticMenuData();
	const [domains] = useState(staticMenuData[0]);
	const [levels] = useState(staticMenuData[1]);
	//const [practices, setPractices] = useState(staticMenuData[2]);
	const [open, setOpen] = useState([]);
	const [active, setIndex] = useState('AC');

	const handleDomainToggle = (key) => () => {
		const currentIndex = open.indexOf(key);
		const newOpen = [...open];

		if (currentIndex === -1) {
			newOpen.push(key);
		} else {
			newOpen.splice(currentIndex, 1);
		}

		setOpen(newOpen);
	};
	const dispatch = useDispatch();
	useEffect(()=>{
		dispatch(pageData("AC"))
	},[])
const handleData =(a)=>{
	if(a==="AM"){
		toast.error("No Data Available")
		return true

	}
	if(a==="SA")
	{
		toast.error("No Data Available")
		return true
	}
	setIndex(a);
	dispatch(pageData(a))

}

const classes = useStyles();
	return (
		<div  style={{background:'#D4D4D4'}}>
				<List component='nav' style={{overflow:""}}>
				{/* {CreateDomainList(domains)} */}
			
				
						<Fragment >
							<ListItem
								button
								onClick={()=>handleData("AC")}
								className={(active==='AC')?classes['active']:null}
							>
								
								<ListItemText primary="Access Control (AC)" />
							</ListItem>
							<ListItem
								button
								onClick={()=>handleData("AT")}
								className={(active==='AT')?classes['active']:null}
							>
								
								<ListItemText primary=" Awareness & Training (AT)"/>
							</ListItem>
							<ListItem
								button
								onClick={()=>handleData("AU")}
								className={(active==='AU')?classes['active']:null}
							>
								
								<ListItemText primary="Audit & Accountability (AU)" />
							</ListItem>
							<ListItem
								button
								onClick={()=>handleData("CM")}
								className={(active==='CM')?classes['active']:null}
							>
								
								<ListItemText primary="Configuration Management (CM)" />
							</ListItem>
							<ListItem
								button
								onClick={()=>handleData("IA")}
								className={(active==='IA')?classes['active']:null}
							>
								
								<ListItemText primary="Identification & Authentication (IA)" />
							</ListItem>
							<ListItem
								button
								onClick={()=>handleData("IR")}
								className={(active==='IR')?classes['active']:null}
							>
								
								<ListItemText primary="Incident Response (IR)" />
							</ListItem>
							<ListItem
								button
								onClick={()=>handleData("MA")}
								className={(active==='MA')?classes['active']:null}
							>
								
								<ListItemText primary="Maintenance (MA)" />
							</ListItem>
							<ListItem
								button
								onClick={()=>handleData("MP")}
								className={(active==='MP')?classes['active']:null}
							>
								
								<ListItemText primary="Media Protection (MP)" />
							</ListItem>
							<ListItem
								button
								onClick={()=>handleData("RE")}
								className={(active==='RE')?classes['active']:null}
							>
								
								<ListItemText primary="Recovery (RE)" />
							</ListItem>
							<ListItem
								button
								onClick={()=>handleData("PS")}
								className={(active==='PS')?classes['active']:null}
							>
								
								<ListItemText primary="Personnel Security (PS)" />
							</ListItem>
							<ListItem
								button
								onClick={()=>handleData("PE")}
								className={(active==='PE')?classes['active']:null}
							>
								
								<ListItemText primary="Physical Protection (PE)" />
							</ListItem>
							<ListItem
								button
								onClick={()=>handleData("RM")}
								className={(active==='RM')?classes['active']:null}

							>
								
								<ListItemText primary="Risk Management (RM)" />
							</ListItem>
							<ListItem
								button
								onClick={()=>handleData("CA")}
								className={(active==='CA')?classes['active']:null}

							>
								
								<ListItemText primary="Security Assessment (CA)" />
							</ListItem>
							<ListItem
								button
								onClick={()=>handleData("SC")}
								className={(active==='SC')?classes['active']:null}

							>
								
								<ListItemText primary="System & Communications Protection (SC) " />
							</ListItem>
							<ListItem
								button
								onClick={()=>handleData("SI")}
								className={(active==='SI')?classes['active']:null}

							>
								
								<ListItemText primary="System & Information Integrity (SI) " />
							</ListItem>
							<ListItem
								button
								onClick={()=>handleData("AM")}
								className={(active==='AM')?classes['active']:null}

							>
								
								<ListItemText primary="Asset Management (AM)" />
							</ListItem>
							<ListItem
								button
								onClick={()=>handleData("SA")}
								className={(active==='SA')?classes['active']:null}

							>
								
								<ListItemText primary="Situational Awareness (SA)" />
							</ListItem>

						
						</Fragment>
		
		
			</List>
			{/* <List component='nav' className='border-right'>
				
				{domains.map((domain) => {
					return (
						<Fragment key={`fragment-${domain.abbreviation}`}>
							<ListItem
								button
								onClick={handleDomainToggle(
									`${domain.name}-${domain.abbreviation}`
								)}
								key={`${domain.name}-${domain.abbreviation}`}>
								{open.indexOf(`${domain.name}-${domain.abbreviation}`) !==
								-1 ? (
									<ExpandLess />
								) : (
									<ExpandMore />
								)}
								<ListItemText primary={`${domain.name}`} />
							</ListItem>
							<Collapse
								key={`collapse-${domain.name}-${domain.abbreviation}`}
								in={
									open.indexOf(`${domain.name}-${domain.abbreviation}`) !== -1
								}
								timeout='auto'
								unmountOnExit>
								<List componet='div'>
									<ListItem>
										<ListItemLink
											href={`/${domain.abbreviation}/${levels[0].abbreviation}`}
											className='m-0 px-5'>
											<ListItemText primary='Level 1' />
										</ListItemLink>
									</ListItem>
									<ListItem>
										<ListItemText className='m-0 px-5' primary='Level 2' />
									</ListItem>
									<ListItem>
										<ListItemText className='m-0 px-5' primary='Level 3' />
									</ListItem>
									<ListItem>
										<ListItemText className='m-0 px-5' primary='Level 4' />
									</ListItem>
									<ListItem>
										<ListItemText className='m-0 px-5' primary='Level 5' />
									</ListItem>
								</List>
							</Collapse>
						</Fragment>
					);
				})}
			</List> */}
		</div>
	);
};

export default MainMenu;

// function CreateDomainList(domainList) {
//     const [open, setOpen] = useState(false);

//     const handleClick = () => {
//         setOpen(!open);
//     };

//     return domainList.map((domain) => (
//         <>
//             <ListItem button onclick={handleClick}
//                 key={`${domain.name}-${domain.abbreviation}`}
//                 href={`${domain.abbreviation}`}>
//                 <ListItemText primary={`${domain.name}`} />
//                 {open ? <ExpandLess /> : <ExpandMore />}
//             </ListItem>
//             <Collapse key={`collapse-${domain.name}-${domain.abbreviation}`} in={open} timeout="auto" unmountOnExit>

//             </Collapse>
//         </>
//     ));
// }
