import Domain from '../Models/DomainModel';
import Level from '../Models/LevelModel';
// import Practice from '../Models/PracticeModel';

class MenuHelper {
	getStaticMenuData() {
		let staticDomains = [];
		let staticLevels = [];
		let staticPractices = [];

		// let domainsData = ["Access Control", "Asset Management", "Audit Accountability", "Awareness and Training",];
		let newDomain1 = new Domain();
		let newDomain2 = new Domain();
		let newDomain3 = new Domain();
		let newDomain4 = new Domain();

		newDomain1.name = 'Access Control';
		newDomain1.abbreviation = 'AC';
		staticDomains.push(newDomain1);

		newDomain2.name = 'Asset Management';
		newDomain2.abbreviation = 'AM';
		staticDomains.push(newDomain2);

		newDomain3.name = 'Audit Accountability';
		newDomain3.abbreviation = 'AA';
		staticDomains.push(newDomain3);

		newDomain4.name = 'Awareness and Training';
		newDomain4.abbreviation = 'AT';
		staticDomains.push(newDomain4);

		let newLevel1 = new Level();
		let newLevel2 = new Level();
		let newLevel3 = new Level();
		let newLevel4 = new Level();
		let newLevel5 = new Level();

		newLevel1.name = 'Level 1';
		newLevel1.abbreviation = 'L1';
		staticLevels.push(newLevel1);

		newLevel2.name = 'Level 2';
		newLevel2.abbreviation = 'L2';
		staticLevels.push(newLevel2);

		newLevel3.name = 'Level 3';
		newLevel3.abbreviation = 'L3';
		staticLevels.push(newLevel3);

		newLevel4.name = 'Level 4';
		newLevel4.abbreviation = 'L4';
		staticLevels.push(newLevel4);

		newLevel5.name = 'Level 5';
		newLevel5.abbreviation = 'L5';
		staticLevels.push(newLevel5);

		return [staticDomains, staticLevels, staticPractices];
	}
}

export default MenuHelper;
