import React from 'react';
import { useState } from 'react';
import {
  Button,
  makeStyles,
  Typography,

} from '@material-ui/core';

import MainHeader from '../mainHeader';
import { renderToString } from "react-dom/server";
import jsPDF from "jspdf";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import "jspdf-autotable";
const useStyles = makeStyles({
  table: {
    minWidth: 550,
  },
});

function createData(name, calories) {
  return { name, calories };
}
function createData1(SecurityRequirement, Secur1, AssessorValue, DoDValue, Comments_Recommendation) {
  return { SecurityRequirement, Secur1, AssessorValue, DoDValue, Comments_Recommendation };
}

const rows = [
  createData(-8, "Total Point Deduction"),
  createData(2, "Number of Deficient Controls"),
  createData(98 + "%", "Percentage of Compliant Controls"),

];
const rows1 = [
  createData1("3.1.1*", "Limit system access to authorized users,processesactingonbehalfofauthorizedusers,anddevices(includingothersystems).", 5, "", "AVIRTEK AIM/ACS System implements this capability"),
  createData1("3.1.2*", "Limit system access to the types oftransactionsandfunctionsthatauthorizedusersarepermittedtoexecute.", 5, "", "AVIRTEK AIM/ACS system implements this capability."),
  createData1("3.1.3*", "ControltheflowofCUIinaccordancewithapprovedauthorizations.", 5, "", "AVIRTEK Information Technology Cybersecurity policy control the flow of CUI."),
];

const PracticeView = () => {
  const [data, setData] = useState("102")

  const classes = useStyles();
  const styles = {
    fontFamily: "sans-serif",
    textAlign: "center"
  };
  const colstyle = {
    width: "auto"
  };
  const tableStyle = {
    width: "100%"
  };
  const [data1, Setdata] = useState([
    { name: "-8", profession: "Total Point Deduction" },
    { name: "2", profession: "Number of Deficient Controls" },
    { name: "98%", profession: "Football Player" },

  ])
  const [data3, Setdata3] = useState([
    { security_code: "3.2.1", security: "Keanu Reeves", assessor: "5", dod: " 12 ", comments: "yyuguyasd" },
    { security_code: "3.4.2", security: "Keanu Reeves", assessor: "5", dod: " 21 ", comments: "yyuguyasd" },
    { security_code: "2.3.2", security: "Keanu Reeves", assessor: "5", dod: " 12 ", comments: "yyuguyasd" },
    { security_code: "3.2.3", security: "Keanu Reeves", assessor: "5", dod: " 11 ", comments: "yyuguyasd" },])
  const print = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const marginTop = 40;

    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const company_name = "Company Name:";
    const report_date = "Self-Report Date:";
    const report_desc = "Summary of Self-Assessment Report:";
    const table_desc = "NISTSP800-171 DoD Self-AssessmentReport";
    const headers = [["102", "Total Score"]];
    const headers1 = [["Security Requirement", "Assessor Value", "DoD Value", "Comments/Recommendations"]];


    const data = data1.map(elt => [elt.name, elt.profession]);
    const data2 = data3.map(elt => [elt.security_code, elt.security, elt.assessor, elt.dod, elt.comments]);

    let content = {
      theme:"grid",
      startY: 150,
      margin: { left: 200 },
      tableWidth: 200,
      head: headers,
      body: data,
      headerStyles: {
        fillColor: "#385FC7",
        textColor: [0, 0, 0],
        halign: 'center',
     lineWidth: 1,
    lineColor: [0, 0, 0]
    },
    bodyStyles: {lineColor: [0, 0, 0]}
    };

    let content1 = {
      tableLineColor: [189, 195, 199],
      tableLineWidth: 0.75,
      columnStyles: {
        0: { cellWidth: 140, cellPadding: { left: 10 ,top:5},rowHeight: 22 },
        1: { cellWidth: 90, cellPadding: { left: 10 ,top:5} },
        2: { cellWidth: 90, cellPadding: { left: 20 ,top:5} },
        3: { cellWidth: 195, cellPadding: { left: 40,top:5 } },
      },

      headStyles:{
fillColor:"#FFFFFF",
textColor:"#000",

      },
      theme: "grid",
      startY: 360,
      body: headers1,
    };
    let content2 = {
      tableLineColor: [189, 195, 199],
      tableLineWidth: 0.75,
      theme:"grid",
      
      columnStyles: {
        0: { cellWidth: 40, cellPadding: { left: 10 } },
        1: { cellWidth: 100, cellPadding: { left: 10 } },
        2: { cellWidth: 90, cellPadding: { left: 40 } },
        3: { cellWidth: 90, cellPadding: { left: 40 } },
        4: { cellWidth: 195, cellPadding: { left: 70 } },

      },
      startY: 382,
      body: data2
    };

    doc.text(company_name, marginLeft, 40);
    doc.text(report_date, marginLeft, 60);
    doc.text(report_desc, marginLeft, 100);
    doc.autoTable(content);
    doc.text(table_desc, 150, 330);
    doc.autoTable(content1);
    doc.autoTable(content2);
    doc.setLineWidth(1)

    // doc.autoTable(content1,marginTop);
    doc.save("report.pdf")
  };
  var count = 0;
  var markedCheckbox = document.getElementsByName('checkedImplemented');
  for (var checkbox of markedCheckbox) {
    if (checkbox.checked)
      count++;
    console.log(count);
  }

  return (
    <>
      <MainHeader />
      <Typography style={{ textAlign: "left", marginLeft: "15%" }} variant='h6' >
        Company Name:
      </Typography>
      <Typography style={{ textAlign: "left", marginLeft: "15%" }} variant='h6' >
        Self-Report Date:
      </Typography>
      <Button style={{ marginLeft: "60%", backgroundColor: "#385FC7" }} onClick={print} variant="contained" >
        Download
      </Button>
      <br />
      <br />
      <Typography variant='h6' >
        Summary of Self-Assessment Report
      </Typography>
      <div style={{ marginLeft: "35%", width: "100%" }}>
        <TableContainer style={{ width: "30%" }} component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead style={{ backgroundColor: "#385FC7" }}>
              <TableRow>
                <TableCell style={{ color: "white" }} align="center"><b>102</b></TableCell>
                <TableCell style={{ color: "white" }} align="center"><b>Total Score</b></TableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.name}>
                  <TableCell align="center" component="th" scope="row">
                    <b> {row.name}</b>
                  </TableCell>
                  <TableCell align="center"><b>{row.calories}</b></TableCell>

                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <Typography style={{ marginTop: "5%" }} variant='h6' >
        Detailed of the NISTSP800-171DoD <br />Self-AssessmentReport
      </Typography>
      <div style={{ marginLeft: "15%", width: "100%" }}>
        <TableContainer style={{ width: "70%" }} component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead style={{ backgroundColor: "#385FC7" }}>
              <TableRow>
                <TableCell style={{ color: "white" }} align="center" colSpan={2}><b>SecurityRequirement</b></TableCell>
                <TableCell style={{ color: "white" }} align="center"><b>AssessorValue</b></TableCell>
                <TableCell style={{ color: "white" }} align="center"><b>DoDValue</b></TableCell>
                <TableCell style={{ color: "white" }} align="center"><b>Comments/Recommendation</b></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows1.map((row) => (
                <TableRow key={row.name}>
                  <TableCell component="th" scope="row">
                    {row.SecurityRequirement}
                  </TableCell>
                  <TableCell align="center">{row.Secur1}</TableCell>
                  <TableCell align="center">{row.AssessorValue}</TableCell>
                  <TableCell align="center">{row.DoDValue}</TableCell>
                  <TableCell align="center">{row.Comments_Recommendation}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default PracticeView;
