const initial_state = {
    data: {},
    userData:[],
    printData:[],
    isLoggedIn:false,
    isRegister:false,
    images:[],
    header:"",
    checkLIst:[],
    dodValue:[]
}

export default function facebookReducer(state = initial_state, action) {
    switch (action.type) {

        case "LOGIN": {
            return {
                ...state,
               userData:action.data,
                isLoggedIn:true

            }
        }
        case "LOGOUT": {
            return {
                ...state,
               userData:[],
                isLoggedIn:false,
                isRegister:false
            }
        }
        case "DATA": {
            return {
                ...state,
               data:action.data,
                isLoggedIn:true

            }
        }
        case "SETIMAGE": {
            return {
                ...state,
                images:action.data,
              

            }
        }
        case "SETCHECKLIST": {
            return {
                ...state,
                checkLIst:action.data,
              

            }
        }
        case "SETDOD": {
            return {
                ...state,
                dodValue:action.data,
              

            }
        }
        
        case "SETHEADER": {
            return {
                ...state,
                header:action.header,
              

            }
        }
        case "CLEARIMAGE": {
            return {
                ...state,
                images:[],
              

            }
        }
        case "UPDATEDATA": {
            return { 
                ...state,
                printData: state.printData.map((existingLink, currentIndex) => action.index === currentIndex ? action.data : existingLink),
                isLoggedIn:true

            }
        }
       
        case "SETDATA": {
            return { 
                ...state,
                printData: [...state.printData, action.data],
                isLoggedIn:true

            }
        }
        case "REGISTER": {
            return { 
                ...state,
                isRegister:true
            }
        }
        case "CLEARREG": {
            return { 
                ...state,
                isRegister:false
            }
        }
        default:
            return initial_state
    }
}