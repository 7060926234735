import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';


export default function PublicRoute (props) {
    const { path, isLogin, component} = props;

	return (
		<Route path={props.path} exact render={() => {
			return props.isLogin ? <Redirect to="/dashboard" /> : <div>{props.component}</div>
		}} ></Route>
	);
}
PublicRoute.propTypes = {
	isLogin:  PropTypes.bool.isRequired,
	path: PropTypes.any,
	component: PropTypes.any,

};