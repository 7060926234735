class ActionList {
	constructor() {
		this.id = 0;
		this.title = '';
		this.type = '';
		this.description = '';
		this.cve_code = '';
		this.comment = '';
		this.practice_id = 0;
	}
}

export default ActionList;
