import { PropTypes } from 'prop-types';
import React from 'react';
import { useState } from 'react';
import {
	makeStyles,
	TextField,
	Typography,
	Button,
	Grid,
} from '@material-ui/core';
import { default as GetPracticeData } from '../Helpers/PracticeHelper';
import { useFormik } from 'formik';

const useStyles = makeStyles((theme) => ({
	root: {},
	headerUnderline: {
		textDecoration: 'underline',
	},
}));

const PracticeDialogDiscussion = (props) => {


	const classes = useStyles();
	const { code } = props;
	const originalDiscussion = GetPracticeData().filter(
		(practice) => practice.code === code
	)[0];

	const handleDiscussionSubmit = (values) => {
		alert(JSON.stringify(values, null, 2));
	};

	const [discussion] = useState(originalDiscussion);

	const formik = useFormik({
		initialValues: {
			discussion: '',
		},
		onSubmit: (values) => handleDiscussionSubmit(values),
	});

	return (
		<>
			<form onSubmit={formik.onSubmit}>
				<Grid container direction={'column'} justifyContent='flex-start'>
					<Typography component='h6' className={classes.headerUnderline}>
						Discussion
					</Typography>
					<TextField
						name='Discussion'
						value={discussion}
						fullWidth={true}
						multiline={true}
					/>
					<Button variant={'contained'} type='submit'>
						Update
					</Button>
				</Grid>
			</form>
		</>
	);
};

PracticeDialogDiscussion.propTypes = {
	code: PropTypes.string,
};

export default PracticeDialogDiscussion;
