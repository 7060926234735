import React, { useEffect } from 'react';
import {
  fade,
  ThemeProvider,
  withStyles,
  makeStyles,
  createMuiTheme,
} from '@material-ui/core/styles';
import MainHeader from '../mainHeader';

import InputBase from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import { green } from '@material-ui/core/colors';
import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';
import {Typography,Container} from '@material-ui/core';
import { Button } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { register,clearReg,userLogin } from "../Store/Actions/data"
import { useDispatch,useSelector } from 'react-redux'
import { toast } from 'react-toastify'; 
import "./RegistrationPage.css"

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'black',
      fontWeight:'bold'
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#84C5A3',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.2)',
      },
      '&:hover fieldset': {
        borderColor: 'yellow',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#84C5A3',
      },
    },
   
    
  },
  
 
})(TextField);

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: 16,
    width: 'auto',
    padding: '10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}))(InputBase);

const useStylesReddit = makeStyles((theme) => ({
  root: {
    border: '1px solid #e2e2e1',
    overflow: 'hidden',
    borderRadius: 4,
    backgroundColor: '#fcfcfb',
     boxShadow:'0px 1px 0px rgba(63, 63, 68, 0.05), 0px 1px 3px rgba(63, 63, 68, 0.15)',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:hover': {
      backgroundColor: '#fff',
    },
    '&$focused': {
      backgroundColor: '#fff',
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
  },
  focused: {},
}));

function RedditTextField(props) {
  const classes = useStylesReddit();

  return <TextField InputProps={{ classes, disableUnderline: true }} {...props} />;
}


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
    borderColor: 'green',
  },
  heading:{
    padding:'15px 25px',
    borderBottom: '1px solid rgba(0,0,0,.1)',
    display:'block',
    width:'100%',
    textAlign:'left',
    fontWeight:600,
    fontSize:18,
    marginBottom:30,
  },
  mxauto:{
    margin:'auto'
  },
  Link:{
    textTransform:'uppercase',
    fontSize:'12px',
    color:'#000',
    textDecoration:'none',
    transitions:'all .4s',
    '&:hover': {
      color: '#84C5A3',
      fontWeight:'bold'
    },
  }
}));


const theme = createMuiTheme({
  palette: {
    primary: green,
  },
});

export default function CustomizedInputs() {
  const classes = useStyles();

  const [values, setvalue] = React.useState({
    firstName: "",
    user_name: "",
    password: "",
    confirmPassword: "",

  })

  const onHandleChange = (e) => {
    let key = e.target.id;
    let value = e.target.value;
    setvalue((prev) => { return ({ ...prev, [key]: value }) })
console.log(values);
  }
const history=useHistory();
  const LoginPage=()=>{
    history.push("/login");
  }
  const dispatch = useDispatch();
const handleRegister=()=>{

if(values['firstName']===""){
    toast.error("Enter Company Name");
}
var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

  if (!pattern.test(values["user_name"])&&values['firstName']!=="") {
    toast.error("Enter Valid Email");
  }
  if (values['password']===""&&pattern.test(values["user_name"])&&values['firstName']!=="") {
    toast.error("Enter Password");
    return true

  }
  if (values['password']!==""&&pattern.test(values["user_name"])&&values['firstName']!==""&&values['password'].length<8) {
    toast.error("Password must be at least 8 characters");
    return true

  }
  if (values['confirmPassword']===""&&values['password']!==""&&pattern.test(values["user_name"])&&values['firstName']!=="") {
    toast.error("Enter Confirm Password");

  }
  if ( values['confirmPassword']!==values['password']&&values['confirmPassword']!==""&&values['password']!==""&&pattern.test(values["user_name"])&&values['firstName']!=="") {
    toast.error("Passwords do not match.");

  }
  if ( values['confirmPassword']===values['password']&&values['confirmPassword']!==""&&values['password']!==""&&pattern.test(values["user_name"])&&values['firstName']!=="") {
    dispatch(register(values));
  }
}
const isRegister = useSelector((state) => state.data.isRegister)
const isLoggedIn = useSelector((state) => state.data.isLoggedIn)

useEffect(()=>{
  console.log("register",isRegister);
if(isRegister){
  dispatch(userLogin(values))
}
},[isRegister])
useEffect(()=>{
  if(isLoggedIn){
    history.push("/login")
    dispatch(clearReg())

  }
  },[isLoggedIn])

  return (<>
    <Grid container spacing={0}>
		<Grid item xs={12}>
			<MainHeader />
		</Grid>
    </Grid>
    <div id="div">
      <Container style={{maxWidth:"650px", margin:'0 auto'}}>
    <form className={classes.root} noValidate >
     <Card className={classes.root} style={{marginBottom:'20px',marginTop:"12%"}}>

       <Grid container spacing={2}>
       <Typography className={classes.heading} style={{padding:'22px 30px 12px'}}>
       Registration to AVIRTEK CMMC Online Service
            <CssBaseline />    

          </Typography>
        <Grid item xs={12}>
         <CssTextField type="text" style={{borderColor:"black",width:"80%"}}
        className={classes.margin}
        label="Company Name"
        variant="outlined"
        id="firstName"
        onChange={onHandleChange}
        value={values.firstName}

        // id="custom-css-outlined-input"
      />
      
       
        </Grid>
        <Grid item xs={12}>
         <CssTextField  style={{borderColor:"black",width:"80%" }}
        className={classes.margin}
        type="email"
        label="Email"
        variant="outlined"
        id="user_name"
        onChange={onHandleChange}
        value={values.user_name}
        // id="custom-css-outlined-input"
      />
      
        </Grid>
        <Grid item xs={12}>
         <CssTextField  style={{borderColor:"black",width:"80%" }}
        className={classes.margin}
        type="password"
        label="Password"
        variant="outlined"
        id="password"
        onChange={onHandleChange}
        value={values.password}

        // id="custom-css-outlined-input"
      />
        </Grid>
        <Grid item xs={12}>
         <CssTextField  style={{borderColor:"black",width:"80%" }}
        className={classes.margin}
        label="Confirm Password"
        type="password"
        id="confirmPassword"
        variant="outlined"
        value={values.confirmPassword}

        onChange={onHandleChange}
        // id="custom-css-outlined-input"
      />
        </Grid>
 
       <Grid xs={12} alignItems="center"> <Button variant="contained" onClick={handleRegister} style={{backgroundColor: "#e69832",marginBottom:"8%",marginTop:"2%", width:'150px', fontSize:'16px', paddingTop:'10px'} }>
        Register
      </Button></Grid>
      </Grid>
      
     </Card>
     <p  className={classes.mxauto}><Link  onClick={LoginPage} className={classes.Link}>Login</Link></p>
    </form>
    </Container>
    </div>
    </>
  );
}
