import React, { useEffect } from 'react';
import { useState } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import CircularProgress from '@material-ui/core/CircularProgress';
import URL from "../../src/config.json"
import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
	makeStyles,
	Typography,
	Checkbox,
	FormGroup,
	FormControlLabel,
	Grid,
	IconButton,
	Menu,
	TextareaAutosize,
	MenuItem,
	Button,
	Modal,
	Input,
	TextField,
	ListItem,
	List,
	CardMedia
} from '@material-ui/core';
import ImageIcon from '@material-ui/icons/Image';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertical from '@material-ui/icons/MoreVert';

import GetAppIcon from '@material-ui/icons/GetApp';
// import FileViewer from 'react-file-viewer';

import GetPracticeData from '../Helpers/PracticeHelper';
import GetActionData from '../Helpers/ActionsHelper';
import GetAttachmentData from '../Helpers/AttachmentHelper';
import PracticeToolbar from './_PracticeToolbar';
import MainHeader from '../mainHeader';
import MainMenu from '../mainMenu';
import {getImage,imageDelete,clearImage,saveSectionData,saveThisSectionData} from "../Store/Actions/data"
import PublishIcon from '@material-ui/icons/Publish';
// import data from "../Data/access-controler.json"
import { useDispatch, useSelector } from 'react-redux';
const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		textAlign: 'start',
		alignItems: 'center',
	},
	'& label.Mui-focused': {
		color: 'black',
	  },
	  '& .MuiInput-underline:after': {
		borderBottomColor: 'black',
	  },
	  '& .MuiOutlinedInput-root': {
		'& fieldset': {
		  borderColor: 'black',
		},
		'&:hover fieldset': {
		  borderColor: 'black',
		},
		'&.Mui-focused fieldset': {
		  borderColor: 'black',
		},
		'&.MuiTypography-h5':{
			color:'#fff'
		}
		 },
	header: {
		boxShadow: theme.shadows['1'],
	},
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	  },
	  paper: {
		backgroundColor: theme.palette.background.paper,
		border: '2px solid #000',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
	  },
	headerUnderline: {
		textAlign: 'start',
		fontWeight: '750',
	},
	status: {
		marginLeft: theme.spacing(2),
	},
	attachments: {
		paddingLeft: theme.spacing(),
	},
	attachmentButton: {
		margin: theme.spacing(0.5),
		marginBottom: theme.spacing(2),
		padding: theme.spacing(0.5),
		'&:first-child': {
			marginLeft: theme.spacing(2),
		},
	},
	black: {
		color: theme.palette.common.black,
	},
	formcontorl:{
		borderBottomColor:'#fff!important'
	},
	
	accordionPadding: { padding: theme.spacing(2) },
}));


const PracticeView = () => {
	const data = useSelector((state) => state.data.data)

//console.log("desc",data)
const [s_practice,setPractice]=useState("")
const[check,setChech]=useState(1)
	const classes = useStyles();
	const practices = GetPracticeData(1, 1); 
	const attachments = GetAttachmentData();
	const actions = GetActionData(1);
	const [isSaved, setIsSaved] = useState(true);
	const [attachmentMenu, setAttachmentMenu] = useState(null);
	const [statusItems, setStatusItems] = useState([]);
	const [actionItems, setActionItems] = useState([]);

	const [state, setState] = React.useState({});
	let x = [];

	const [noOfQue, setNoOfQue] = useState(0);
	const[printData,setPrintData]=useState([]);

	const handleStatusChange = (setMethod, checkedItems) => (event) => {
		const { name } = event.target;
		if (!name) {
			return;
		}
		let itemsArray = [...checkedItems];
		let checkedIndex = itemsArray.indexOf(name);

		if (checkedIndex === -1) {
			setMethod((checkedItems) => [...checkedItems, name]);
		} else {
			if (checkedIndex === 0) {
				itemsArray = itemsArray.slice(1);
			} else if (checkedIndex < itemsArray.length) {
				itemsArray.splice(checkedIndex, 1);
			} else {
				itemsArray = itemsArray.slice(0, -1);
			}
			setMethod(itemsArray);
		}
		setIsSaved(false);
	};

	const handleStatusItemChange = handleStatusChange(
		setStatusItems,
		statusItems
	);
	const handleActionItemChange = handleStatusChange(
		setActionItems,
		actionItems
	);

	const handleAttachmentMenuOpen = (event) => {
		setAttachmentMenu(event.currentTarget);
	};

	const saveSectionCheckInfo = (params)=>(e)=>{
		setState({
			...state,
			[e.target.name]: e.target.checked
		  });

		dispatch(saveSectionData(params));
	}

	const saveSectionInfo = (e)=>{
		setDisable(false)
		console.log(state);
		setState({
			...state,
			[e.target.name]: e.target.value
		  });
	}

	const handleAttachmentMenuClose = () => {
		setAttachmentMenu(null);
	};

	const handleAttachmentView = (event) => {
		//ToDo:  code to view attachment in browser
	};
	const [spacing, setSpacing] = React.useState(2);
	const handleAttachmentDownload = (imageSrc,imgName) => {
//console.log(imageSrc,imgName);
		fetch(imageSrc, {
			method: "GET",
			headers: {}
		  })
			.then(response => {
			  response.arrayBuffer().then(function(buffer) {
				const url = window.URL.createObjectURL(new Blob([buffer]));
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", imgName); //or any other extension
				document.body.appendChild(link);
				link.click();
			  });
			})
			.catch(err => {
			  console.log(err);
			});
	};
useEffect(()=>{
	if(state["dod_"+s_practice]===''){
		setDisable(true)
		setState({['assessor_value_'+s_practice] :""})
		return true
	}
	console.log((state["dod_"+s_practice]===undefined),"asjaskjaskj")
	if(state["dod_"+s_practice]===undefined){
		setDisable(true)
	}
	else{
		setDisable(false)
	}
},[s_practice,check,state])

	const [expanded, setExpanded] = React.useState(false);
	const [values,setValues]=useState({
		assessor_value:"",
		dod_value:"",
		assessor_recommendations:""
	
	})
	const handleChange = (panel) => (event, isExpanded) => {

		 setChech(check+1);

	
		setStatusItems([])

		setDisable(true)

		setValues({	assessor_value:"",
		dod_value:"",
		assessor_recommendations:""})
	  setExpanded(isExpanded ? panel : false);
	  getImages(panel);
	};
const handleQuestionUp =()=>{ 
	setNoOfQue(prevCount => prevCount + 1)
}
const handleQuestionDown =()=>{
}

const [disable,setDisable]=useState(true)

const saveData=(data,data2)=>{
 let code = data2.split("*");
let value ={ security_code: code[0], security:data.desc, assessor: "", dod: "", comments: "yyuguyasd" }
	setPrintData((prev)=>{return({...prev,[data2]: value	})})
}
const [assessor,setAssessor]=useState(true)
const handleBlur =(practice)=>{
	if(state["dod_"+practice]===''){
		setDisable(true)
		setState({['assessor_value_'+practice] :""})
		return true
	}
}
// useEffect(()=>{
// 	setDisable(false)
// },[state])

const [data1,setData]=useState([])
const dispatch=useDispatch()
const images = useSelector((state) => state.data.images)
const dodValue = useSelector((state) => state.data.dodValue[0])
const checkList = useSelector((state) => state.data.checkLIst)
useEffect(()=>{
	if(dodValue){
		console.log("dodValue==>",dodValue);
		setState((prev)=>{return{...prev,["dod_"+dodValue.section]: dodValue.dod}})
		setState((prev)=>{return{...prev,["assessor_value_"+dodValue.section]: dodValue.accesor}})
		setState((prev)=>{return{...prev,["assessor_recommendations_"+dodValue.section]: dodValue.recommendation}})
}
	},[dodValue])
useEffect(()=>{
	console.log("here>>>>>>>>>")
for(let i=0;i<checkList.length;i++){
	if(checkList[i].type == 1){
		let key = 'eval_qid_'+checkList[i].section+'_'+checkList[i].qid

		  setState((prev)=>{return{...prev,[key]: true}})
	}

	if(checkList[i].type == 0){
		let key = 'imp_status_qid_'+checkList[i].section+'_'+checkList[i].qid
		setState((prev)=>{return{...prev,[key]: true}})
	}
}
},[checkList])
const header = useSelector((state) => state.data.header)
useEffect(()=>{
	setData(images);
},[images])
const getImages=(practice)=>{
	setPractice(practice)
	dispatch(getImage(practice))
}

const [open, setOpen] = React.useState(false);
const [imgpath, setImgpath] = React.useState("");
const [imgtype, setImgType] = React.useState("");


  const docs = [
    { uri: imgpath },
  ];
const handleOpen = (a,b) => {
	setImgpath(a)
	setImgType(b)

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

const getHtml = (text) =>{
	const x = text.split('*');
	
	if(x.length>1){
		return (<React.Fragment><span>{x[0]}</span> <span style={{color:'#D3D3D3'}}>{x[1]}</span></React.Fragment>)
	}else{
		return (<span style={{color:'#D3D3D3'}}>{x[0]}</span>)
	}

}

 
const downloadReport = () =>{
}
const saveDataOnClick = (practice) =>{
	let code = practice.split("*");

	let data_ = {
		dod:state['dod_'+practice],
		recommendation:state['assessor_recommendations_'+practice],
		accesor:state['assessor_value_'+practice],
		section:practice,
		security_code:code[0],
		description:data[practice].desc
	} 

	dispatch(saveThisSectionData(data_));
}



const [loader,setLoader]=useState(true)
const handleDelete=(id,index,practice)=>{
	setLoader(false)
dispatch(imageDelete(id))
images.splice(index, 1);

setAttachmentMenu(null);
dispatch(getImage(practice))

setTimeout(function(){ dispatch(getImage(practice)) }, 3000);
setTimeout(function(){ setLoader(true) }, 3000);


}



 const file = 'www.africau.edu/images/default/sample.pdf'
const type = 'pdf'
console.log('state',state);
	return (
		<div 	onClick={(event) => event.stopPropagation()}>	

			<Grid container spacing={0}>
		<Grid item xs={12}>
			<MainHeader />
		</Grid>
		<Grid item id='divMenu' xs={3} sm={2}>
			<MainMenu />
		</Grid>
		<Grid item id='mainContent' xs={9} sm={10} 	onClick={(event) => event.stopPropagation()}>
		<div className='main-gradient' style={{height:"70px",paddingLeft:"10px",paddingTop:"30px",paddingBottom:"25px"}}>
			<div style={{marginTop:"-50px"}} ><h2 style={{textAlign:"left",marginTop:"40px", color:'#ffa500'}}>{header} </h2>	<h3 style={{textAlign:"left",marginTop:"-20px", color:'#ffa500'}}>NIST SP 800-171 </h3>	<h4 style={{textAlign:"left",marginTop:"-20px",fontWeight:"normal",fontFamily:"sans-serif", color:'#ffa500'}}> Control # *&#10100;CMMC Practice #&#10101;</h4></div>
		

			</div>
			{(Object.keys(data)).map((practice, index) => (
 //onClick={()=>{getImages(practice)}} //onClick={()=>{getImages(practice)}}
				<Accordion key={practice+"_"+index}  className={classes.root} onClick={()=>{getImages(practice)}} onFocus={(event) => event.stopPropagation()} expanded={expanded === practice} onChange={handleChange(practice)}>

					<AccordionSummary
						className={classes.header}
						expandIcon={<ExpandMoreIcon />}>
						<Typography
							variant={
								'h5'
							} className={classes.h5title}>{getHtml(practice)}</Typography>
					</AccordionSummary>
		

					<AccordionDetails style={{display:'block'}}>
						<div className='card-body'>
							<div id='description' className={classes.root}>
								<Typography variant='h6' className={classes.headerUnderline}>
									Description
								</Typography>
								<p>{data[practice].desc}</p>
							</div>
						</div>
						<div className={classes.status}>
									<Typography variant='h6' className={classes.headerUnderline}>
										Evaluation
									</Typography>
									{data[practice].data.map((prac, index1) => {
							
							console.log('eval_qid_3.1.1*[AC.1.001]_0' === 'eval_qid_'+practice+'_'+index1)
										return(
											<FormGroup key={`qid_`+index1} direction='column'>
										<FormControlLabel  onFocus={(event) => event.stopPropagation()}	onClick={(event) => event.stopPropagation()}
											control={
												<Checkbox
													// onFocus={handleQuestionUp}
													onChange={saveSectionCheckInfo({qid:index1,section:practice,type:1})}
													name={'eval_qid_'+practice+'_'+index1}
													className={classes.black}
													checked={(state['eval_qid_'+practice+'_'+index1])?true:false}
													color='default'
													onFocus={(event) => event.stopPropagation()} 
													onClick={(event) => event.stopPropagation()}
												/>
											}
											label={prac}
										/>
									</FormGroup>)
									})}
								</div>
									<div id='discussion' className={classes.root}>
							
								<p>{practice.discussion}</p>
								<div className={classes.status}>
									<Typography variant='h6' className={classes.headerUnderline}>
										Implementation Status
									</Typography>
									<FormGroup direction='column'>
										<FormControlLabel onFocus={(event) => event.stopPropagation()}	onClick={(event) => event.stopPropagation()}
											control={
												<Checkbox
												onChange={saveSectionCheckInfo({qid:0,section:practice,type:0})}
												name={'imp_status_qid_'+practice+'_0'}
												className={classes.black}
												checked={(state['imp_status_qid_'+practice+'_0'])?true:false}
												color='default'
												onClick={(event) => event.stopPropagation()}
												onFocus={(event) => event.stopPropagation()} 
												/>
											}
											label='Implemented'
										/>  
										<FormControlLabel onFocus={(event) => event.stopPropagation()}	onClick={(event) => event.stopPropagation()}
											control={
												<Checkbox
												onChange={saveSectionCheckInfo({qid:1,section:practice,type:0})}
												name={'imp_status_qid_'+practice+'_1'}
												className={classes.black}
												checked={(state['imp_status_qid_'+practice+'_1']) ? true : false}
												color='default'
												onClick={(event) => event.stopPropagation()}
												/>
											}
											label='Partially Implemented'
										/>
										<FormControlLabel onFocus={(event) => event.stopPropagation()}	onClick={(event) => event.stopPropagation()}
											control={
												<Checkbox
												onChange={saveSectionCheckInfo({qid:2,section:practice,type:0})}
												name={'imp_status_qid_'+practice+'_2'}
												className={classes.black}
												checked={(state['imp_status_qid_'+practice+'_2'])?true:false}
												color='default'
												onClick={(event) => event.stopPropagation()}
												/>
											}
											label='Not Applicable'
										/>
										<FormControlLabel onFocus={(event) => event.stopPropagation()}	onClick={(event) => event.stopPropagation()}
											control={
												<Checkbox
												onChange={saveSectionCheckInfo({qid:3,section:practice,type:0})}
												name={'imp_status_qid_'+practice+'_3'}
												className={classes.black}
												checked={(state['imp_status_qid_'+practice+'_3'])?true:false}
												color='default'
												onClick={(event) => event.stopPropagation()}
												/>
											}
											label='Inherited'
										/>
									</FormGroup>
								</div>
							</div>
				



				


				
							<div id='assessors' className={classes.root} 	onFocus={(event) => event.stopPropagation()}	onClick={(event) => event.stopPropagation()}>
						
								<Typography variant={'h6'} className={classes.headerUnderline}>
								DoD Value
								</Typography>
								<p>{practice.discussion}</p>
								<div className={classes.status}>
									<FormGroup direction='column'>
										<FormControlLabel
											control={
												<TextField onBlur={()=>{handleBlur(practice)}} value={state['dod_'+practice]} onClick={(event) => event.stopPropagation()} aria-label="minimum height"  maxLength={1} onChange={saveSectionInfo} id="dod_value" name={'dod_'+practice} style={{width:"20%"}}  type="number" placeholder="DoD Value " />
											}	
											className={classes.formcontorl}  
										/>
									</FormGroup>
								</div>
							</div>
				
							<div id='assessors' className={classes.root} onFocus={(event) => event.stopPropagation()}	onClick={(event) => event.stopPropagation()} >
								<Typography  variant={'h6'} className={classes.headerUnderline}>
								Assessor Value 
								</Typography>
								<p>{practice.discussion}</p>
								<div className={classes.status}>
									<FormGroup direction='column'>
										<FormControlLabel
											control={
												<Input disabled={disable}  onClick={(event) => event.stopPropagation()}  aria-label="minimum height" value={state['assessor_value_'+practice]} maxLength={1} style={{width:"20%"}} id="assessor_value"  name={'assessor_value_'+practice} onChange={saveSectionInfo} type="number" placeholder="Assessor Value " />
											}	
										/>
									</FormGroup>
								</div>
							</div> 

<div id='assessors' className={classes.root} onFocus={(event) => event.stopPropagation()}	onClick={(event) => event.stopPropagation()} >
								<Typography variant={'h6'} className={classes.headerUnderline} >
									Assessors Recommendations
								</Typography>
								<p>{practice.discussion}</p>
								<div className={classes.status}>
									<FormGroup direction='column'>
										<FormControlLabel
											control={
												<TextareaAutosize onClick={(event) => event.stopPropagation()} onChange={saveSectionInfo}  value={state['assessor_recommendations_'+practice]} id="assessor_recommendations" name={'assessor_recommendations_'+practice} aria-label="minimum height" style={{width:"50%", height:'70px'}} minRows={3} placeholder="Assessors Recommendations" />
											}	
										/>
									</FormGroup>
								
								</div>
							</div>
				


				
									<div className='attachment'>
								<Typography style={{marginTop:"1%", marginBottom:'15px'}} variant='h6' className={classes.headerUnderline}>
									Attachments(Information Policy){/*<PublishIcon style={{marginBottom:"-0.8%"}} fontSize="large" />*/}
								</Typography>

								{loader ? (

<div>
{data1.map((practice1, index1) => (
<>
		<Grid onClick={(event) => event.stopPropagation()}
									
										container
										className={classes.attachments}
										direction='column'
										justifyContent='flex-start'
										alignItems='center' style={{marginBottom:'5px'}}>
										<Grid
											container
											direction='row'
											justifyContent='space-between'>
											
											<CardMedia

        className={classes.media}
        image=	{practice1.imagepath}
        title="Paella dish"
      />
{/*  */}


<Grid  item xs={12} >

        <Grid  container spacing={spacing}>
			<Grid>
			<Typography  className={classes.attachmentButton} style={{minWidth:'500px', marginLeft:'0', paddingLeft:'0', dispaly:'block'}}>
											{practice1.imagename}
											</Typography>
			</Grid>
            {/* <Grid  item>
			<ImageIcon onClick={()=>{handleOpen("http://3.17.207.43:9000/"+practice1.imagepath,practice1.imagetype)}}/>
            </Grid> */}
			<Grid  item style={{marginLeft:'auto'}}>
			<GetAppIcon onClick={()=>{handleAttachmentDownload(URL.SERVER_URL+"/"+practice1.imagepath,practice1.imagepath)}}/>
            </Grid>
			<Grid  item>
			<DeleteIcon onClick={()=>{handleDelete(practice1.id,index1,practice)}} />

            </Grid>
        </Grid>
      </Grid>


										</Grid>
									</Grid>
								
</>
))} 

					</div>
				) : (<div style={{textAlign:"center"}}>
					<CircularProgress style={{position:"relative",color:"#FFA500"}} />
					</div>
				)}

							

							
							</div>
							<div className='toolbar'>
								<PracticeToolbar
									isSaved={isSaved}
									setIsSaved={setIsSaved}
									name={`toolbar-${practice.code}`}
									{...practice}
									data={data[practice]}
									index={practice}
									code=""
									code1={{dod_value:state['dod_'+practice],assessor_value:state['assessor_value_'+practice]}}
									saveData={saveDataOnClick}
									downloadReport={downloadReport}
								/>
							</div>
						
							
					</AccordionDetails> 
					
					
				</Accordion>
			))}
				{/* {open ? (

<div>
				

					</div>
				) : (
<CircularProgress />
				)} */}

			
					</Grid>
				</Grid>
		</div>
	);
};

export default PracticeView;
