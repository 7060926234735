// import Domain from '../Models/DomainModel';
// import Level from '../Models/LevelModel';
import PracticeModel from '../Models/PracticeModel';

function getPracticeData(domain, level) {
	var staticPractices = [];

	var newPractice1 = new PracticeModel();
	newPractice1.domain = 1;
	newPractice1.level = 1;
	newPractice1.code = 'AC.1.001';
	newPractice1.name = 'Information Limited to Authorized users';
	newPractice1.description =
		'Limit information system access to authorized users, processes acting on behalf of authorized users, or devices (including other information systems)';
	newPractice1.discussion =
		'Access enforcement mechanisms can be employed at the application and service level to provide increased information security.  ' +
		'Other systems include systems internal and external to the organization. ' +
		'This requirement focuses on account management for systems and applications.';
	staticPractices.push(newPractice1);

	var newPractice2 = new PracticeModel();
	newPractice2.domain = 1;
	newPractice2.level = 1;
	newPractice2.code = 'AC.1.002';
	newPractice2.name = 'Access Control Limit 2';
	newPractice2.description =
		'A differ practice that limits how access is controlled';
	newPractice2.discussion = 'Another discussion that is for this practice';
	staticPractices.push(newPractice2);

	var newPractice3 = new PracticeModel();
	newPractice3.domain = 1;
	newPractice3.level = 2;
	newPractice3.code = 'AC.1.005';
	newPractice3.name = 'Access Control Limit 5';
	newPractice3.description =
		'A differ practice that limits how access is controlled this time at a different level';
	newPractice2.discussion =
		'The discussion that is for this practice 5 at level 2';
	staticPractices.push(newPractice3);

	if (domain && level) {
		return staticPractices.filter(
			(practice) => practice.domain === domain && practice.level === level
		);
	} else {
		return staticPractices;
	}
}

export default getPracticeData;
