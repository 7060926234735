class PracticeModel {
	constructor() {
		this.id = 0;
		this.name = '';
		this.code = '';
		this.description = '';
		this.discussion = '';
		this.comment = '';
		this.status = '';
		this.domain = 0;
		this.level = 0;
	}
}

export default PracticeModel;
