import React from 'react';
import { useState } from 'react';
import {
	makeStyles,
	Checkbox,
	Typography,
	FormGroup,
	FormControlLabel,
	Divider,
	Button,
	Grid,
	TextField,
	FormControl as form,
	IconButton,
	Menu,
	MenuItem,
} from '@material-ui/core';
import { useFormik } from 'formik';

import MoreVert from '@material-ui/icons/MoreVert';

import GetActionData from '../Helpers/ActionsHelper';
import { default as ActionModel } from '../Models/ActionListModel';

const _debug = false;

const useStyles = makeStyles((theme) => ({
	root: {},
	addAction: {
		margin: theme.spacing(2),
	},
}));

const PracticeDialogActions = (props) => {
	const classes = useStyles();
	const [actions, setActions] = useState(GetActionData(1));
	const [actionItems, setActionItems] = useState([]);
	const testPractice = 'Test.0-000';
	const [actionMenu, setActionMenu] = useState(null);
	const formik = useFormik({
		initialValues: {
			description: '',
		},
		onSubmit: (values) => handleActionCreate(values),
	});

	const handleActionCreate = (values) => {
		if (_debug) {
			alert(JSON.stringify(values, null, 2));
		}
		let newActions = actions;
		let newAction = new ActionModel();
		newAction.description = values.description;
		newActions.push(newAction);
		setActions(newActions);
		formik.resetForm();
	};

	const handleStatusChange = (setMethod, checkedItems) => (event) => {
		const { name } = event.target;
		if (!name) {
			return;
		}
		let itemsArray = [...checkedItems];
		let checkedIndex = itemsArray.indexOf(name);

		if (checkedIndex === -1) {
			setMethod((checkedItems) => [...checkedItems, name]);
		} else {
			if (checkedIndex === 0) {
				itemsArray = itemsArray.slice(1);
			} else if (checkedIndex < itemsArray.length) {
				itemsArray.splice(checkedIndex, 1);
			} else {
				itemsArray = itemsArray.slice(0, -1);
			}
			console.log(itemsArray);
			setMethod(itemsArray);
		}
	};

	const handleActionItemChange = handleStatusChange(
		setActionItems,
		actionItems
	);

	const handleActionMenuOpen = (event) => {
		setActionMenu(event.currentTarget);
	};
	const handleActionMenuClose = () => {
		setActionMenu(null);
	};

	return (
		<>
			<Typography variant='h6' className={classes.headerUnderline}>
				Actions
			</Typography>
			{actions.map((action, index) => (
				<FormGroup key={`${testPractice} - ${index}`}>
					<Grid container direction='row' justifyContent='space-between'>
						<Grid item xs={11}>
							<FormControlLabel
								className={classes.root}
								control={
									<Checkbox
										checked={
											// actionItems.indexOf(`action-${testPractice}-${index}`) !==-1
											actionItems.includes(`action-${testPractice}-${index}`)
										}
										onChange={handleActionItemChange}
										name={`action-${testPractice}-${index}`}
										className={classes.black}
										color='default'
									/>
								}
								label={action.description}
							/>
						</Grid>
						<Grid item xs={1}>
							<IconButton onClick={handleActionMenuOpen}>
								<MoreVert />
							</IconButton>
							<Menu
								anchorEl={actionMenu}
								open={Boolean(actionMenu)}
								onClose={handleActionMenuClose}
								anchorOrigin={{
									vertical: 'center',
									horizontal: 'left',
								}}
								transformOrigin={{
									vertical: 'center',
									horizontal: 'right',
								}}>
								<MenuItem>Edit</MenuItem>
								<MenuItem>Delete</MenuItem>
							</Menu>
						</Grid>
					</Grid>
				</FormGroup>
			))}
			<Divider component='hr' />
			<form onSubmit={formik.handleSubmit}>
				<Grid container direction={'row'} justifyContent={'flex-end'}>
					<TextField
						className={classes.addAction}
						fullWidth={true}
						name={'description'}
						value={formik.values.description}
						onChange={formik.handleChange}
						label={'New Action'}
					/>
					<Button
						variant={'contained'}
						className={classes.addAction}
						type={'submit'}>
						Create
					</Button>
				</Grid>
			</form>
		</>
	);
};

export default PracticeDialogActions;
